import React from 'react';
import Web3 from 'web3';
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { TheDapp } from './TheDapp.js'
import { core } from './tools/core.js'
const weon = (onThis, doThis=()=>{}) => {
  window.ethereum.on(onThis, doThis)
}

// props.chain - chain if any to restrict dapp users to
// props.onChainConnect - function to trigger on chain connect
export const Dapp = (props) => {
  
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([])
  const [mmBtnText, setMMBtnText] = React.useState("Connect")

  const [stuff, setStuff] = React.useState({})
  const [chainImg, setChainImg] = React.useState("")
  // attached to the accountsChanged event listener
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  function handleChainChange() {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId))
    if (props.chain.length > 0) {
     enforceChain(props.chain, () => {
      if (core.hasOwnProperty(chainMap(window.ethereum.chainId))) {
        setStuff(core[chainMap(window.ethereum.chainId)])
        props.onChainConnect()
      } else {
        alert("failed to load data")
      }
     })
    } else {
      props.onChainConnect()
    }
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((newAccounts) => {
          handleNewAccounts(newAccounts)
          setConnected(true)})
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      weon('accountsChanged', handleNewAccounts);
      return () => {
        weon('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      weon('chainChanged', handleChainChange);
      return () => {
        weon('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])

  const addyToIcon = (addy) => {
    return stuff.links.icons.pre + addy + stuff.links.icons.post
  }
  const setTheChainIcon = () => {
    if (stuff.hasOwnProperty("core")) {
      setChainImg(addyToIcon(stuff.tokens[stuff.core.icon]["address"]))
    } 
  }

  React.useEffect(()=>{
    if (stuff.hasOwnProperty("core")) {
      setTheChainIcon()
    }
  }, [stuff])
  

  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

  return (
    <div className={"Dapp Dapp-connected--" + connected}>
       <button className="btn-connect" onClick={connectMM}>
        {(chainImg != "") ? <img src={chainImg} /> : ""}
        <span>{mmBtnText}</span>
       </button>
       <TheDapp 
        web3={web3} 
        connected={connected} 
        accounts={accounts} 
        stuff={stuff}
        addyToIcon={addyToIcon}
        />
    </div>

  );
}

