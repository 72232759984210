import React from 'react';
import { Joo } from './tools/JooishWeb3.js'
import { data } from './tools/airdrop.js'
import { core, getDecimals, isLP } from './tools/core.js'
import { chainMap, enforceChain } from './tools/ChainTools.js'
// props.web3 - web3 instance
// props.connected - is TheDapp connected
// props.accounts - active eth accounts
// props.stuff - the data from core.js for this chain

export const TheDapp = (props) => {
  var joo = new Joo(props.web3)
  
  const [hasFetched, set_hasFetched] = React.useState(false)

  React.useEffect(()=>{
  	let b, req
  	if (props.connected && !hasFetched && props.stuff.hasOwnProperty("tokens")) {
  		
  		set_hasFetched(true)
      
      lookup()
  	  
 
  	}
  }, [props.connected, props.stuff, hasFetched])

  const [rowNumber, setRowNumber] = React.useState(1000);
  const lookup = () => {
    let a = window.ethereum.selectedAddress.toLowerCase()
    let b = data
    data.forEach( (row,i)=>{
      //console.log(row.Address.toLowerCase())
      if (row.Address.toLowerCase() === a) {
        setRowNumber(i)
      }
    }
      )
  }

  const [theRow, setTheRow] = React.useState({})

  React.useEffect(()=>{
    if (rowNumber < 1000) {
      setTheRow(data[rowNumber])
    }
  }, [rowNumber])


  return (
    <div className={"TheDapp"}>
       <h1 className="title">The Builders</h1>
       <p className="subtitle">A Farmgod DAO project on Optimism and Fantom.</p>
       
       <div className={"sider shower shower--" + rowNumber}>
         <h2>you.builder == {(rowNumber < 1000) ? theRow.Username : "user"}</h2>
         <p>Welcome {(rowNumber < 1000) ? theRow.Username : "user"}.</p>
         <p className="small">At the time of the DeBank snapshot you had:</p>
         <p>Followers: {(rowNumber < 1000) ? theRow.Followers : 0}</p>
         <p>Net Worth: {(rowNumber < 1000) ? theRow["Net Worth"] : 0}</p>
         <p className="small">You will receive:</p>
         <p>Boson: {(rowNumber < 1000) ? theRow["Boson"] : 0}</p>
         <p>Lepton: {(rowNumber < 1000) ? theRow["Lepton"] : 0}</p>
       </div>
       <p className={"antishower antishower--" + rowNumber}>If you see this message, and you were not eligible for round 1. If you believe you were eligible, please join the <a href="https://discord.gg/qYjpnWCthT" target="_blank">discord</a>.</p>
       
       
       <h2>About The Builders</h2>
       <p>This is an experimental project.</p>
       <p>The plan is tokenize the fundamental particles of the universe, and write smart contracts that govern their interactions.</p>
       <p>Anybody can contribute. Join the <a href="https://discord.gg/qYjpnWCthT" target="_blank">discord</a>.</p>
       
    </div>

  );
}

