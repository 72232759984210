export const data = [
  {
    "Username": "an0n",
    "Address": "0x7bfee91193d9df2ac0bfe90191d40f23c773c060",
    "Followers": "17,370",
    "Net Worth": "$20,496,622",
    "Boson Weight": 26,
    "Lepton Weight": 67,
    "Boson": 395,
    "Lepton": 5.447
  },
  {
    "Username": "Maxus",
    "Address": "0x8de3c3891268502f77db7e876d727257dec0f852",
    "Followers": "1,300",
    "Net Worth": "$12,000",
    "Boson Weight": 26,
    "Lepton Weight": 67,
    "Boson": 395,
    "Lepton": 5.447
  },
  {
    "Username": "33EE",
    "Address": "0x33eecc48943aaeabb5328a25ff28eb85f67945c2",
    "Followers": "5,799",
    "Net Worth": "$19,532,062",
    "Boson Weight": 18,
    "Lepton Weight": 66,
    "Boson": 274,
    "Lepton": 5.381
  },
  {
    "Username": "Gekko",
    "Address": "0xb72ed8401892466ea8af528c1af1d0524bc5e105",
    "Followers": "16,997",
    "Net Worth": "$695,587",
    "Boson Weight": 26,
    "Lepton Weight": 29,
    "Boson": 392,
    "Lepton": 2.338
  },
  {
    "Username": "ngmi",
    "Address": "0x7532a9e3e9475337c8a907428e35932a20959fdf",
    "Followers": "11,110",
    "Net Worth": "$626,049",
    "Boson Weight": 22,
    "Lepton Weight": 28,
    "Boson": 340,
    "Lepton": 2.277
  },
  {
    "Username": "meta",
    "Address": "0x6b62909ad3f315833a5e5062b670034a3016910b",
    "Followers": "5,940",
    "Net Worth": "$420,229",
    "Boson Weight": 18,
    "Lepton Weight": 25,
    "Boson": 276,
    "Lepton": 2.061
  },
  {
    "Username": "nmstarchild",
    "Address": "0xf7b10d603907658f690da534e9b7dbc4dab3e2d6",
    "Followers": "2,275",
    "Net Worth": "$340,812",
    "Boson Weight": 13,
    "Lepton Weight": 24,
    "Boson": 200,
    "Lepton": 1.956
  },
  {
    "Username": "Punkk",
    "Address": "0xc69ae428f6049e78d445f053d2c1df879c59b34c",
    "Followers": "1,242",
    "Net Worth": "$236,256",
    "Boson Weight": 11,
    "Lepton Weight": 22,
    "Boson": 164,
    "Lepton": 1.785
  },
  {
    "Username": "fierydev",
    "Address": "0x5d22db0ddc365dbdc0983441e2906d51855a142d",
    "Followers": "3,977",
    "Net Worth": "$226,231",
    "Boson Weight": 16,
    "Lepton Weight": 22,
    "Boson": 242,
    "Lepton": 1.765
  },
  {
    "Username": "Selandr",
    "Address": "0x0cead33eb6adf4860b6b8f66687f51567e111973",
    "Followers": 956,
    "Net Worth": "$224,624",
    "Boson Weight": 10,
    "Lepton Weight": 22,
    "Boson": 150,
    "Lepton": 1.762
  },
  {
    "Username": "Bogdan",
    "Address": "0xa734439d26ce4dbf43ed7eb364ec409d949bb369",
    "Followers": "3,463",
    "Net Worth": "$215,742",
    "Boson Weight": 15,
    "Lepton Weight": 22,
    "Boson": 231,
    "Lepton": 1.745
  },
  {
    "Username": "hilonom",
    "Address": "0x524b7c9b4ca33ba72445dfd2d6404c81d8d1f2e3",
    "Followers": 760,
    "Net Worth": "$210,874",
    "Boson Weight": 9,
    "Lepton Weight": 21,
    "Boson": 139,
    "Lepton": 1.735
  },
  {
    "Username": "matze",
    "Address": "0x9c4d592042f959254485d443bbc337d29572264f",
    "Followers": 805,
    "Net Worth": "$168,241",
    "Boson Weight": 9,
    "Lepton Weight": 20,
    "Boson": 142,
    "Lepton": 1.639
  },
  {
    "Username": "ponziscam",
    "Address": "unfollowed",
    "Followers": 790,
    "Net Worth": "$153,574",
    "Boson Weight": 9,
    "Lepton Weight": 20,
    "Boson": 141,
    "Lepton": 1.602
  },
  {
    "Username": "hotsauce",
    "Address": "0xb0fabe3bcac50f065dbf68c0b271118ddc005402",
    "Followers": "3,785",
    "Net Worth": "$151,608",
    "Boson Weight": 16,
    "Lepton Weight": 20,
    "Boson": 238,
    "Lepton": 1.597
  },
  {
    "Username": "Will",
    "Address": "0x873dedc08c68af519003fe8fd23a1122369bbaa2",
    "Followers": 876,
    "Net Worth": "$146,098",
    "Boson Weight": 10,
    "Lepton Weight": 20,
    "Boson": 146,
    "Lepton": 1.583
  },
  {
    "Username": "Epgp",
    "Address": "0xdabdf9708935d7b85c9b4af5aaf3ad859f0e19ca",
    "Followers": 683,
    "Net Worth": "$141,983",
    "Boson Weight": 9,
    "Lepton Weight": 19,
    "Boson": 134,
    "Lepton": 1.571
  },
  {
    "Username": "0xDefiDon",
    "Address": "0x1e18b8b6f27568023e0b577cbee1889391b2f444",
    "Followers": 526,
    "Net Worth": "$92,106",
    "Boson Weight": 8,
    "Lepton Weight": 17,
    "Boson": 123,
    "Lepton": 1.41
  },
  {
    "Username": "nerd",
    "Address": "0x9d17bb55b57b31329cf01aa7017948e398b277bc",
    "Followers": "8,163",
    "Net Worth": "$74,757",
    "Boson Weight": 20,
    "Lepton Weight": 17,
    "Boson": 307,
    "Lepton": 1.338
  },
  {
    "Username": "LOLOG",
    "Address": "0xf6168297046ca6fa514834c30168e63a47256af4",
    "Followers": "2,962",
    "Net Worth": "$72,008",
    "Boson Weight": 14,
    "Lepton Weight": 16,
    "Boson": 219,
    "Lepton": 1.326
  },
  {
    "Username": "DeFiat",
    "Address": "0xb4dc4c7460c58e7652cd615675a1f707ebb67e9a",
    "Followers": "1,003",
    "Net Worth": "$69,580",
    "Boson Weight": 10,
    "Lepton Weight": 16,
    "Boson": 153,
    "Lepton": 1.315
  },
  {
    "Username": "SMIN",
    "Address": "0x7798ba9512b5a684c12e31518923ea4221a41fb9",
    "Followers": 484,
    "Net Worth": "$60,541",
    "Boson Weight": 8,
    "Lepton Weight": 16,
    "Boson": 120,
    "Lepton": 1.27
  },
  {
    "Username": "0x964e…94fb",
    "Address": "0x964e5e17798aea56ef906da5cb617c69719e94fb",
    "Followers": "1,353",
    "Net Worth": "$59,511",
    "Boson Weight": 11,
    "Lepton Weight": 16,
    "Boson": 169,
    "Lepton": 1.264
  },
  {
    "Username": "0x43a6…a8b3",
    "Address": "0x43a66d39c830174ad5d06a68e2fe21e748dea8b3",
    "Followers": 206,
    "Net Worth": "$55,592",
    "Boson Weight": 6,
    "Lepton Weight": 15,
    "Boson": 90,
    "Lepton": 1.243
  },
  {
    "Username": "remche",
    "Address": "0x5bb73e04b810527b14b87c37eff3d62481f2d416",
    "Followers": 83,
    "Net Worth": "$56,680",
    "Boson Weight": 4,
    "Lepton Weight": 15,
    "Boson": 66,
    "Lepton": 1.249
  },
  {
    "Username": "0x0c59…f664",
    "Address": "0x0c59785411720c19e4d80e942be60ca7527ef664",
    "Followers": 9,
    "Net Worth": "$53,617",
    "Boson Weight": 2,
    "Lepton Weight": 15,
    "Boson": 32,
    "Lepton": 1.232
  },
  {
    "Username": "BigDickBandit",
    "Address": "0xd653971fa19ef68bc80becb7720675307bfb3ee6",
    "Followers": 205,
    "Net Worth": "$52,917",
    "Boson Weight": 6,
    "Lepton Weight": 15,
    "Boson": 90,
    "Lepton": 1.228
  },
  {
    "Username": "rivne",
    "Address": "0x12cb6f5eb0d90f8978deb060c46539e8b8725509",
    "Followers": "1,702",
    "Net Worth": "$48,870",
    "Boson Weight": 12,
    "Lepton Weight": 15,
    "Boson": 182,
    "Lepton": 1.204
  },
  {
    "Username": "PigPig",
    "Address": "0x8e17543e388eea7f99bc0736476875dd8d950037",
    "Followers": 682,
    "Net Worth": "$48,513",
    "Boson Weight": 9,
    "Lepton Weight": 15,
    "Boson": 134,
    "Lepton": 1.201
  },
  {
    "Username": "PEOPLE",
    "Address": "0xde731672533d772c4a21bfa0353299c320151de8",
    "Followers": "5,502",
    "Net Worth": "$46,930",
    "Boson Weight": 18,
    "Lepton Weight": 15,
    "Boson": 269,
    "Lepton": 1.191
  },
  {
    "Username": "0xd74c…1f33",
    "Address": "0xd74c23f4f7a7c390074bd583c444fbf60eae1f33",
    "Followers": 63,
    "Net Worth": "$46,226",
    "Boson Weight": 4,
    "Lepton Weight": 15,
    "Boson": 61,
    "Lepton": 1.187
  },
  {
    "Username": "Echolon",
    "Address": "0xcb938bbed7789ee8aabd259a8cb789aa3500bbf3",
    "Followers": 182,
    "Net Worth": "$44,894",
    "Boson Weight": 6,
    "Lepton Weight": 15,
    "Boson": 86,
    "Lepton": 1.178
  },
  {
    "Username": "metis",
    "Address": "0xfbf2a4db9aef5757f3a758859a9132af55754fa3",
    "Followers": 508,
    "Net Worth": "$44,078",
    "Boson Weight": 8,
    "Lepton Weight": 14,
    "Boson": 122,
    "Lepton": 1.173
  },
  {
    "Username": "YaroslavSaft",
    "Address": "0xbea4749947b782a9e9ed98230859bf9784a1eb2d",
    "Followers": 941,
    "Net Worth": "$40,519",
    "Boson Weight": 10,
    "Lepton Weight": 14,
    "Boson": 149,
    "Lepton": 1.148
  },
  {
    "Username": "Aero",
    "Address": "0xf4117b338fa4eb2e27579d8ffee3923b0f15d85a",
    "Followers": 774,
    "Net Worth": "$36,748",
    "Boson Weight": 9,
    "Lepton Weight": 14,
    "Boson": 140,
    "Lepton": 1.121
  },
  {
    "Username": 9688,
    "Address": "0x99391c6f4d33ddac56e0856db4ef0013851031bd",
    "Followers": 355,
    "Net Worth": "$34,885",
    "Boson Weight": 7,
    "Lepton Weight": 14,
    "Boson": 108,
    "Lepton": 1.106
  },
  {
    "Username": "Dice",
    "Address": "0x4eb68ecf77639e64c9ed0826978940fec1be9f3f",
    "Followers": 295,
    "Net Worth": "$34,046",
    "Boson Weight": 7,
    "Lepton Weight": 14,
    "Boson": 101,
    "Lepton": 1.1
  },
  {
    "Username": "BIGMISTAKE",
    "Address": "0xdd621f5c25555442384c6196427da57039e8922d",
    "Followers": 21,
    "Net Worth": "$33,681",
    "Boson Weight": 3,
    "Lepton Weight": 14,
    "Boson": 42,
    "Lepton": 1.097
  },
  {
    "Username": "Unbanked",
    "Address": "0xfdf3b3b1cfe5f4967daba1719693d589ba790c1c",
    "Followers": 854,
    "Net Worth": "$32,417",
    "Boson Weight": 9,
    "Lepton Weight": 13,
    "Boson": 145,
    "Lepton": 1.086
  },
  {
    "Username": "Dr_Leuke",
    "Address": "0x66e655f74887e50d2ffca66bdb48e1815d41e7ef",
    "Followers": "2,711",
    "Net Worth": "$29,742",
    "Boson Weight": 14,
    "Lepton Weight": 13,
    "Boson": 213,
    "Lepton": 1.063
  },
  {
    "Username": "tempo",
    "Address": "0xa2ed15eff01918c23423eb7a848a7dce0beaf87a",
    "Followers": 711,
    "Net Worth": "$28,333",
    "Boson Weight": 9,
    "Lepton Weight": 13,
    "Boson": 136,
    "Lepton": 1.05
  },
  {
    "Username": "0xb05c…0fdd",
    "Address": "0xb05cf01231cf2ff99499682e64d3780d57c80fdd",
    "Followers": 277,
    "Net Worth": "$27,342",
    "Boson Weight": 7,
    "Lepton Weight": 13,
    "Boson": 99,
    "Lepton": 1.041
  },
  {
    "Username": "0xMercury",
    "Address": "0xbc2e9df6281a8e853121dc52dbc8bcc8bbe3ed0e",
    "Followers": 863,
    "Net Worth": "$26,988",
    "Boson Weight": 10,
    "Lepton Weight": 13,
    "Boson": 145,
    "Lepton": 1.038
  },
  {
    "Username": "zlemz",
    "Address": "0xd1533820be9fd2a52c464b05f41fcaa423416461",
    "Followers": 224,
    "Net Worth": "$26,277",
    "Boson Weight": 6,
    "Lepton Weight": 13,
    "Boson": 93,
    "Lepton": 1.031
  },
  {
    "Username": "Erotic",
    "Address": "0x10bac83a258ea7c5a0e4428577bd3ac802650a3d",
    "Followers": 63,
    "Net Worth": "$26,107",
    "Boson Weight": 4,
    "Lepton Weight": 13,
    "Boson": 61,
    "Lepton": 1.029
  },
  {
    "Username": "blink",
    "Address": "0xe10ef7cbd3ea3d11488a98e082c312f077246f7b",
    "Followers": 388,
    "Net Worth": "$25,896",
    "Boson Weight": 7,
    "Lepton Weight": 13,
    "Boson": 111,
    "Lepton": 1.027
  },
  {
    "Username": "zaporol",
    "Address": "0x2c6c246b53caf8ff134e5d9874a29aa393419adb",
    "Followers": 321,
    "Net Worth": "$25,840",
    "Boson Weight": 7,
    "Lepton Weight": 13,
    "Boson": 104,
    "Lepton": 1.026
  },
  {
    "Username": "po1nt",
    "Address": "0x322292a046eb607b95174aba3cd5edbe80b621c0",
    "Followers": 278,
    "Net Worth": "$25,137",
    "Boson Weight": 7,
    "Lepton Weight": 13,
    "Boson": 99,
    "Lepton": 1.019
  },
  {
    "Username": "Mining",
    "Address": "0xf41084c75a910abf87a5c669f4b5481621a21720",
    "Followers": 176,
    "Net Worth": "$25,016",
    "Boson Weight": 6,
    "Lepton Weight": 13,
    "Boson": 85,
    "Lepton": 1.018
  },
  {
    "Username": "sanbangaje",
    "Address": "0x50cbd83114d58797a4989f6cfd618e7e680c3d58",
    "Followers": 76,
    "Net Worth": "$24,985",
    "Boson Weight": 4,
    "Lepton Weight": 13,
    "Boson": 65,
    "Lepton": 1.018
  },
  {
    "Username": "mebabyshark",
    "Address": "0x7e4be95f871504778094060b5a12f43698cc7241",
    "Followers": 770,
    "Net Worth": "$24,188",
    "Boson Weight": 9,
    "Lepton Weight": 12,
    "Boson": 140,
    "Lepton": 1.009
  },
  {
    "Username": "Scarfesse85",
    "Address": "0xed9a711ff766d9e06e08d6b9a60b915eb8dcd6e5",
    "Followers": "4,501",
    "Net Worth": "$24,062",
    "Boson Weight": 17,
    "Lepton Weight": 12,
    "Boson": 252,
    "Lepton": 1.008
  },
  {
    "Username": "Beluga",
    "Address": "0xd8533d518b4a70d8f8a591ca34797d3cce5e1aff",
    "Followers": 68,
    "Net Worth": "$23,279",
    "Boson Weight": 4,
    "Lepton Weight": 12,
    "Boson": 62,
    "Lepton": 1
  },
  {
    "Username": 8675309,
    "Address": "0xf84750ae307d6692daf2c9819dc8b2016f2889a3",
    "Followers": 344,
    "Net Worth": "$22,880",
    "Boson Weight": 7,
    "Lepton Weight": 12,
    "Boson": 107,
    "Lepton": 0.996
  },
  {
    "Username": "jgr33nwood",
    "Address": "0xd8c84eac995150662cc052e6ac76ec184fcf1122",
    "Followers": 223,
    "Net Worth": "$22,940",
    "Boson Weight": 6,
    "Lepton Weight": 12,
    "Boson": 92,
    "Lepton": 0.996
  },
  {
    "Username": "KappaRoss",
    "Address": "0x9bb6acee72ef687e9e0bb1b78c908526d967b745",
    "Followers": 660,
    "Net Worth": "$21,649",
    "Boson Weight": 9,
    "Lepton Weight": 12,
    "Boson": 133,
    "Lepton": 0.982
  },
  {
    "Username": "Wadell",
    "Address": "0x5446021ff557dd9abe2845647afa251aec147811",
    "Followers": 55,
    "Net Worth": "$21,606",
    "Boson Weight": 4,
    "Lepton Weight": 12,
    "Boson": 58,
    "Lepton": 0.981
  },
  {
    "Username": "jeneezote",
    "Address": "0xa223c7692d7a80c98a94ce0f2ac19dfeb8fd47ca",
    "Followers": 134,
    "Net Worth": "$21,337",
    "Boson Weight": 5,
    "Lepton Weight": 12,
    "Boson": 78,
    "Lepton": 0.978
  },
  {
    "Username": "ex1arey888",
    "Address": "0xe6f96b42317a1a594c3abb0e86667fde3490377e",
    "Followers": 266,
    "Net Worth": "$19,996",
    "Boson Weight": 6,
    "Lepton Weight": 12,
    "Boson": 98,
    "Lepton": 0.963
  },
  {
    "Username": "Mrjia",
    "Address": "0xc16d21ea060dade9182931a3d8516f69fc20eeb6",
    "Followers": 178,
    "Net Worth": "$19,815",
    "Boson Weight": 6,
    "Lepton Weight": 12,
    "Boson": 86,
    "Lepton": 0.96
  },
  {
    "Username": "0x9950…28fc",
    "Address": "0x9950eb656729c2812cf429e0eced4be5cd5428fc",
    "Followers": 101,
    "Net Worth": "$19,659",
    "Boson Weight": 5,
    "Lepton Weight": 12,
    "Boson": 71,
    "Lepton": 0.958
  },
  {
    "Username": "Mika",
    "Address": "0xb439b0844d0d9af237ff37dc2379f07b6cd06171",
    "Followers": 291,
    "Net Worth": "$19,314",
    "Boson Weight": 7,
    "Lepton Weight": 12,
    "Boson": 101,
    "Lepton": 0.954
  },
  {
    "Username": "FORK",
    "Address": "0x41e473155fe2080950533af78a5e2ceec17f60bb",
    "Followers": 141,
    "Net Worth": "$18,539",
    "Boson Weight": 5,
    "Lepton Weight": 12,
    "Boson": 79,
    "Lepton": 0.945
  },
  {
    "Username": "pommommam",
    "Address": "0xe8995aee06f518de9865917ead8aa489d1034649",
    "Followers": 357,
    "Net Worth": "$18,155",
    "Boson Weight": 7,
    "Lepton Weight": 12,
    "Boson": 108,
    "Lepton": 0.94
  },
  {
    "Username": "0xd614…3259",
    "Address": "0xd614d1cc06cb7856a8e797f71e133e2fc9203259",
    "Followers": 72,
    "Net Worth": "$18,040",
    "Boson Weight": 4,
    "Lepton Weight": 12,
    "Boson": 63,
    "Lepton": 0.938
  },
  {
    "Username": "berkut",
    "Address": "0x65331fa9893357a3c0459eabb7866babd69c376f",
    "Followers": 496,
    "Net Worth": "$17,512",
    "Boson Weight": 8,
    "Lepton Weight": 12,
    "Boson": 121,
    "Lepton": 0.931
  },
  {
    "Username": "Renegade06",
    "Address": "0xdf974829f6d977370b21e065ae445390cb9d010c",
    "Followers": 668,
    "Net Worth": "$17,504",
    "Boson Weight": 9,
    "Lepton Weight": 12,
    "Boson": 133,
    "Lepton": 0.931
  },
  {
    "Username": "ZAVOD",
    "Address": "0xb3430b686daa829162d2aba22c815ad3b5882f00",
    "Followers": 307,
    "Net Worth": "$17,254",
    "Boson Weight": 7,
    "Lepton Weight": 11,
    "Boson": 103,
    "Lepton": 0.928
  },
  {
    "Username": "Umcka",
    "Address": "0xd273485bdedeb856083dde9a8b293aa556cf8178",
    "Followers": 348,
    "Net Worth": "$17,032",
    "Boson Weight": 7,
    "Lepton Weight": 11,
    "Boson": 107,
    "Lepton": 0.925
  },
  {
    "Username": "x777",
    "Address": "0x786694b02f1d331be540e727f1f2a697c45b57e4",
    "Followers": "1,696",
    "Net Worth": "$16,936",
    "Boson Weight": 12,
    "Lepton Weight": 11,
    "Boson": 182,
    "Lepton": 0.923
  },
  {
    "Username": "ceycey",
    "Address": "0xc5fd654ba7c9c9d6b5710fc347c1306e855ec582",
    "Followers": "1,025",
    "Net Worth": "$16,531",
    "Boson Weight": 10,
    "Lepton Weight": 11,
    "Boson": 154,
    "Lepton": 0.918
  },
  {
    "Username": "alexdebank",
    "Address": "0x2dbb7eaf854059c9fc0484b96d6f2567dc5f3e15",
    "Followers": 536,
    "Net Worth": "$16,064",
    "Boson Weight": 8,
    "Lepton Weight": 11,
    "Boson": 124,
    "Lepton": 0.911
  },
  {
    "Username": "twinkle",
    "Address": "0xb41e31a4ddc1c3ed12ee1ade86f01ff1e3f9fb27",
    "Followers": "4,516",
    "Net Worth": "$16,013",
    "Boson Weight": 17,
    "Lepton Weight": 11,
    "Boson": 252,
    "Lepton": 0.911
  },
  {
    "Username": "0x0fe3…4166",
    "Address": "0x0fe306032fc87b2c1eaa5ae5e8144526d3a14166",
    "Followers": 85,
    "Net Worth": "$15,861",
    "Boson Weight": 4,
    "Lepton Weight": 11,
    "Boson": 67,
    "Lepton": 0.908
  },
  {
    "Username": "Kinship",
    "Address": "0xbb73548a0e6f839fb58e9d59969ba6aedecdf5f1",
    "Followers": 374,
    "Net Worth": "$15,776",
    "Boson Weight": 7,
    "Lepton Weight": 11,
    "Boson": 110,
    "Lepton": 0.907
  },
  {
    "Username": "Lipshizz",
    "Address": "0x538dc2a990b1f910715f37205a311132ec283e5a",
    "Followers": 442,
    "Net Worth": "$15,773",
    "Boson Weight": 8,
    "Lepton Weight": 11,
    "Boson": 116,
    "Lepton": 0.907
  },
  {
    "Username": "0x6b0e…d628",
    "Address": "0x6b0ee8e3e6d5d017650c0c36ed04b8b422d4d628",
    "Followers": 15,
    "Net Worth": "$13,800",
    "Boson Weight": 2,
    "Lepton Weight": 11,
    "Boson": 38,
    "Lepton": 0.877
  },
  {
    "Username": "William_Wallet",
    "Address": "0xe12d731750e222ec53b001e00d978901b134cfc9",
    "Followers": 208,
    "Net Worth": "$13,613",
    "Boson Weight": 6,
    "Lepton Weight": 11,
    "Boson": 90,
    "Lepton": 0.874
  },
  {
    "Username": "popigo",
    "Address": "0x510bd5288f9591d186f6a80f37e5c761ded83652",
    "Followers": 489,
    "Net Worth": "$13,462",
    "Boson Weight": 8,
    "Lepton Weight": 11,
    "Boson": 120,
    "Lepton": 0.872
  },
  {
    "Username": "Gigantea",
    "Address": "0x97641a5f3ee3b0561c6bcc522f7e1bedf2c2985a",
    "Followers": 61,
    "Net Worth": "$12,907",
    "Boson Weight": 4,
    "Lepton Weight": 11,
    "Boson": 60,
    "Lepton": 0.863
  },
  {
    "Username": "cryptorun",
    "Address": "0xada7673b48d656a87cad01bcbafe6b1ee6d621ed",
    "Followers": 196,
    "Net Worth": "$12,853",
    "Boson Weight": 6,
    "Lepton Weight": 11,
    "Boson": 89,
    "Lepton": 0.862
  },
  {
    "Username": "mitya_n_1",
    "Address": "0x1070298571b9156c9497827e116a75d1e383cb6c",
    "Followers": 157,
    "Net Worth": "$12,803",
    "Boson Weight": 5,
    "Lepton Weight": 11,
    "Boson": 82,
    "Lepton": 0.861
  },
  {
    "Username": "Gosufootman",
    "Address": "0x46e77f7eeee688e3358bc7cf33acf51644cb8b02",
    "Followers": 365,
    "Net Worth": "$12,626",
    "Boson Weight": 7,
    "Lepton Weight": 11,
    "Boson": 109,
    "Lepton": 0.858
  },
  {
    "Username": "fantomdegen",
    "Address": "0x2a06913d5b8748b222e1236768c24ca19198b374",
    "Followers": 87,
    "Net Worth": "$12,546",
    "Boson Weight": 4,
    "Lepton Weight": 11,
    "Boson": 68,
    "Lepton": 0.857
  },
  {
    "Username": "Minerva",
    "Address": "0x84912977b668e357198cae78ba108b99cff0489a",
    "Followers": 583,
    "Net Worth": "$12,507",
    "Boson Weight": 8,
    "Lepton Weight": 11,
    "Boson": 127,
    "Lepton": 0.856
  },
  {
    "Username": "yuther",
    "Address": "0xedc2fdcc98b72e6e0406e795e485f1665ec8cfc6",
    "Followers": 109,
    "Net Worth": "$12,476",
    "Boson Weight": 5,
    "Lepton Weight": 11,
    "Boson": 73,
    "Lepton": 0.855
  },
  {
    "Username": "Satoshy",
    "Address": "0xd9af06ca2eb971f818f0c41d40e5b8f89830d7cc",
    "Followers": 503,
    "Net Worth": "$12,396",
    "Boson Weight": 8,
    "Lepton Weight": 11,
    "Boson": 121,
    "Lepton": 0.854
  },
  {
    "Username": "0xa38d…e790",
    "Address": "0xa38d48a063b70693d557e88ff058d4e25897e790",
    "Followers": 106,
    "Net Worth": "$12,392",
    "Boson Weight": 5,
    "Lepton Weight": 11,
    "Boson": 72,
    "Lepton": 0.854
  },
  {
    "Username": "Johnson",
    "Address": "0x3feb9298170a751e4e6c81195912fb6a5784139c",
    "Followers": 242,
    "Net Worth": "$12,338",
    "Boson Weight": 6,
    "Lepton Weight": 11,
    "Boson": 95,
    "Lepton": 0.853
  },
  {
    "Username": "0x88",
    "Address": "0xce68b1394ed29ecaa6974536244d1d2aad4a2745",
    "Followers": 266,
    "Net Worth": "$12,180",
    "Boson Weight": 6,
    "Lepton Weight": 11,
    "Boson": 98,
    "Lepton": 0.85
  },
  {
    "Username": "devilta",
    "Address": "0x0d8fbfce9018950e97ea5b10f4dce118f02fda81",
    "Followers": 430,
    "Net Worth": "$12,077",
    "Boson Weight": 8,
    "Lepton Weight": 10,
    "Boson": 115,
    "Lepton": 0.849
  },
  {
    "Username": "CryptoCool10",
    "Address": "0xfcba88ee3480cd7419a3eba8559d54ed609e86ea",
    "Followers": 191,
    "Net Worth": "$12,028",
    "Boson Weight": 6,
    "Lepton Weight": 10,
    "Boson": 88,
    "Lepton": 0.848
  },
  {
    "Username": "0x1e86…d4ef",
    "Address": "0x1e86218cf301d95b98dac9bd8ebfd5a0b0a7d4ef",
    "Followers": 3,
    "Net Worth": "$12,141",
    "Boson Weight": 1,
    "Lepton Weight": 10,
    "Boson": 22,
    "Lepton": 0.85
  },
  {
    "Username": "vdovinda",
    "Address": "0x8c57d1fab10d4b78f49ef5c6abaeb76a63710f61",
    "Followers": 505,
    "Net Worth": "$11,561",
    "Boson Weight": 8,
    "Lepton Weight": 10,
    "Boson": 121,
    "Lepton": 0.839
  },
  {
    "Username": "0xf966…b561",
    "Address": "0xf9665672645c7370b3e99feab90f20f011a2b561",
    "Followers": 336,
    "Net Worth": "$11,487",
    "Boson Weight": 7,
    "Lepton Weight": 10,
    "Boson": 106,
    "Lepton": 0.838
  },
  {
    "Username": "where_is_profit",
    "Address": "0x785dc7204e2da8694c045e615562e946aadbf914",
    "Followers": "1,749",
    "Net Worth": "$11,198",
    "Boson Weight": 12,
    "Lepton Weight": 10,
    "Boson": 184,
    "Lepton": 0.833
  },
  {
    "Username": "jingge",
    "Address": "0xd0ac50d9f7516be16e2449539394a3967bea03c7",
    "Followers": 251,
    "Net Worth": "$10,949",
    "Boson Weight": 6,
    "Lepton Weight": 10,
    "Boson": 96,
    "Lepton": 0.828
  },
  {
    "Username": "Cryp4o",
    "Address": "0x975d07636e8ca4d78f77ef86cdbd5f8cb3923b8e",
    "Followers": 349,
    "Net Worth": "$10,573",
    "Boson Weight": 7,
    "Lepton Weight": 10,
    "Boson": 107,
    "Lepton": 0.821
  },
  {
    "Username": "kimbjbjbj",
    "Address": "0xe906cd2293cc0c903cc50033b4df876405e5933e",
    "Followers": 67,
    "Net Worth": "$10,530",
    "Boson Weight": 4,
    "Lepton Weight": 10,
    "Boson": 62,
    "Lepton": 0.82
  },
  {
    "Username": "Mantis",
    "Address": "0x2ba838e42126ac349d01c3d1fac85a36266151a4",
    "Followers": 113,
    "Net Worth": "$10,453",
    "Boson Weight": 5,
    "Lepton Weight": 10,
    "Boson": 74,
    "Lepton": 0.818
  },
  {
    "Username": "Mrbiii",
    "Address": "0x1de8355da82cfb8d2453a5ec3fc4c871281ab4bc",
    "Followers": 136,
    "Net Worth": "$10,326",
    "Boson Weight": 5,
    "Lepton Weight": 10,
    "Boson": 78,
    "Lepton": 0.816
  },
  {
    "Username": "wolga",
    "Address": "0x0057bd83a142da84b6404616c683e21e7dd6fa5f",
    "Followers": 120,
    "Net Worth": "$10,183",
    "Boson Weight": 5,
    "Lepton Weight": 10,
    "Boson": 75,
    "Lepton": 0.813
  },
  {
    "Username": "krass",
    "Address": "0xc0b54011af8591ac7f9dbc635ce3660b5db903a7",
    "Followers": 625,
    "Net Worth": "$10,157",
    "Boson Weight": 9,
    "Lepton Weight": 10,
    "Boson": 130,
    "Lepton": 0.813
  },
  {
    "Username": "Buns",
    "Address": "0xfd63bf84471bc55dd9a83fdfa293ccbd27e1f4c8",
    "Followers": 8,
    "Net Worth": "$9,754",
    "Boson Weight": 2,
    "Lepton Weight": 10,
    "Boson": 30,
    "Lepton": 0.804
  },
  {
    "Username": "andreysuperior",
    "Address": "0x713c7df267d9e5de915eae7cd51df2e60645a419",
    "Followers": 360,
    "Net Worth": "$9,789",
    "Boson Weight": 7,
    "Lepton Weight": 10,
    "Boson": 108,
    "Lepton": 0.805
  },
  {
    "Username": "Makovey",
    "Address": "0x2f75ffe98046e4a55fa79daf97b2730d7e707186",
    "Followers": 238,
    "Net Worth": "$9,379",
    "Boson Weight": 6,
    "Lepton Weight": 10,
    "Boson": 94,
    "Lepton": 0.797
  },
  {
    "Username": "0x77",
    "Address": "0x7e8d2190dde46f666ec7e578611b5728dbeafc1a",
    "Followers": "2,224",
    "Net Worth": "$9,371",
    "Boson Weight": 13,
    "Lepton Weight": 10,
    "Boson": 199,
    "Lepton": 0.796
  },
  {
    "Username": "0x88c9…84e4",
    "Address": "0x88c994b0028a77e45528e5cb37a206287f4884e4",
    "Followers": 14,
    "Net Worth": "$9,336",
    "Boson Weight": 2,
    "Lepton Weight": 10,
    "Boson": 37,
    "Lepton": 0.796
  },
  {
    "Username": "bitocin",
    "Address": "0xbc09716f78c97f9a07821d3aec752512228ea17d",
    "Followers": 94,
    "Net Worth": "$9,273",
    "Boson Weight": 5,
    "Lepton Weight": 10,
    "Boson": 69,
    "Lepton": 0.794
  },
  {
    "Username": "voldemar",
    "Address": "0x0f28fa701eda5db150f2c6ccfa220e75cd839efe",
    "Followers": 180,
    "Net Worth": "$9,169",
    "Boson Weight": 6,
    "Lepton Weight": 10,
    "Boson": 86,
    "Lepton": 0.792
  },
  {
    "Username": "GmEth",
    "Address": "0xad05b50b71d1c05e3309e9f99e633a21741b77d9",
    "Followers": "2,158",
    "Net Worth": "$8,997",
    "Boson Weight": 13,
    "Lepton Weight": 10,
    "Boson": 197,
    "Lepton": 0.788
  },
  {
    "Username": "0x608f…82fe",
    "Address": "0x608fe5dd6ee360ede7d4e5d9d0859e0327af82fe",
    "Followers": 0,
    "Net Worth": "$8,718",
    "Boson Weight": 0,
    "Lepton Weight": 10,
    "Boson": 0,
    "Lepton": 0.782
  },
  {
    "Username": "BTS7",
    "Address": "0x6376ba69ade23d5ab1e307250ff277d54163d2d6",
    "Followers": "1,309",
    "Net Worth": "$8,428",
    "Boson Weight": 11,
    "Lepton Weight": 10,
    "Boson": 167,
    "Lepton": 0.776
  },
  {
    "Username": "ifi3",
    "Address": "0xf9c991e5e84954561a4625a0dc0d879fd7fbbebc",
    "Followers": 622,
    "Net Worth": "$8,496",
    "Boson Weight": 9,
    "Lepton Weight": 10,
    "Boson": 130,
    "Lepton": 0.777
  },
  {
    "Username": "Luna755",
    "Address": "0x91fa472fb12ef104d649facce00e3ba43de57a8d",
    "Followers": 141,
    "Net Worth": "$8,491",
    "Boson Weight": 5,
    "Lepton Weight": 10,
    "Boson": 79,
    "Lepton": 0.777
  },
  {
    "Username": "razr",
    "Address": "0x2352936638f06533fdcf56bf399f7e75d1581ae7",
    "Followers": 806,
    "Net Worth": "$8,231",
    "Boson Weight": 9,
    "Lepton Weight": 10,
    "Boson": 142,
    "Lepton": 0.771
  },
  {
    "Username": "maxblok",
    "Address": "0x667ba99128e89f23ab3f053973387f7456c72ac1",
    "Followers": 917,
    "Net Worth": "$8,200",
    "Boson Weight": 10,
    "Lepton Weight": 10,
    "Boson": 148,
    "Lepton": 0.77
  },
  {
    "Username": "0x198d…5703",
    "Address": "0x198d3e137a75292d4929e2a7632d8b1a94495703",
    "Followers": 2,
    "Net Worth": "$8,191",
    "Boson Weight": 1,
    "Lepton Weight": 10,
    "Boson": 19,
    "Lepton": 0.77
  },
  {
    "Username": "kabosu",
    "Address": "0x70cc711c52d00d7229b89271e885b1716903c649",
    "Followers": "1,178",
    "Net Worth": "$7,908",
    "Boson Weight": 11,
    "Lepton Weight": 9,
    "Boson": 161,
    "Lepton": 0.763
  },
  {
    "Username": "tiki",
    "Address": "0x0fe6b003c38e1ef8c139c0f95f283b79fbf9bd97",
    "Followers": "3,542",
    "Net Worth": "$7,665",
    "Boson Weight": 15,
    "Lepton Weight": 9,
    "Boson": 232,
    "Lepton": 0.757
  },
  {
    "Username": "Mehdi007",
    "Address": "0x20628b67ce5cddac1cb95198c0ff5271f39fc048",
    "Followers": "1,066",
    "Net Worth": "$7,600",
    "Boson Weight": 10,
    "Lepton Weight": 9,
    "Boson": 156,
    "Lepton": 0.756
  },
  {
    "Username": "crypto3egal",
    "Address": "0x5defe4f60965528030ee48bba305ed017ab84998",
    "Followers": 677,
    "Net Worth": "$7,518",
    "Boson Weight": 9,
    "Lepton Weight": 9,
    "Boson": 134,
    "Lepton": 0.754
  },
  {
    "Username": "0x7ec6…1022",
    "Address": "0x7ec6be1c8630a54df55d98530b89cdfea1c11022",
    "Followers": 41,
    "Net Worth": "$7,424",
    "Boson Weight": 3,
    "Lepton Weight": 9,
    "Boson": 53,
    "Lepton": 0.751
  },
  {
    "Username": "cryptobomzhik",
    "Address": "0x18a0ede773004339a4de937c5b2e76dc1b6da56a",
    "Followers": 680,
    "Net Worth": "$7,389",
    "Boson Weight": 9,
    "Lepton Weight": 9,
    "Boson": 134,
    "Lepton": 0.75
  },
  {
    "Username": "Fla1white",
    "Address": "0x3c50843f99e1ba286939d69234bc8f3747d2066d",
    "Followers": 128,
    "Net Worth": "$7,242",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 77,
    "Lepton": 0.747
  },
  {
    "Username": "0x9728…0515",
    "Address": "0x97281d9da948509a271d84d11fbcf0377b130515",
    "Followers": "1,114",
    "Net Worth": "$7,169",
    "Boson Weight": 10,
    "Lepton Weight": 9,
    "Boson": 158,
    "Lepton": 0.745
  },
  {
    "Username": "S0tn1k",
    "Address": "0xcf8cf5df28db4f4e8376c90d8cebd5f7a4f73620",
    "Followers": 489,
    "Net Worth": "$7,126",
    "Boson Weight": 8,
    "Lepton Weight": 9,
    "Boson": 120,
    "Lepton": 0.744
  },
  {
    "Username": "Fanelitto",
    "Address": "0x2c78768b6d6b51f01a8d269c47f9648420525cfe",
    "Followers": 282,
    "Net Worth": "$7,026",
    "Boson Weight": 7,
    "Lepton Weight": 9,
    "Boson": 100,
    "Lepton": 0.741
  },
  {
    "Username": "Waifu",
    "Address": "0x4d90cd83c0aac70628607a3b808ac452a06c155c",
    "Followers": 346,
    "Net Worth": "$7,007",
    "Boson Weight": 7,
    "Lepton Weight": 9,
    "Boson": 107,
    "Lepton": 0.741
  },
  {
    "Username": "travchis",
    "Address": "0xa984c0338cc374ce335d3565260e5a0d2a92dbac",
    "Followers": 97,
    "Net Worth": "$6,832",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 70,
    "Lepton": 0.736
  },
  {
    "Username": "miruku",
    "Address": "0xdcc6c130438eeb893ef9d987f3a0e4ccde0ecd4f",
    "Followers": 440,
    "Net Worth": "$6,831",
    "Boson Weight": 8,
    "Lepton Weight": 9,
    "Boson": 116,
    "Lepton": 0.736
  },
  {
    "Username": "0x74c4…3db3",
    "Address": "0x74c4c82ad166b621fa60a730c9d414fc17d33db3",
    "Followers": 0,
    "Net Worth": "$6,743",
    "Boson Weight": 0,
    "Lepton Weight": 9,
    "Boson": 0,
    "Lepton": 0.734
  },
  {
    "Username": "0x397e…d038",
    "Address": "0x397e378287f2aae2eabcae5438d35acbce8cd038",
    "Followers": 168,
    "Net Worth": "$6,772",
    "Boson Weight": 6,
    "Lepton Weight": 9,
    "Boson": 84,
    "Lepton": 0.734
  },
  {
    "Username": "0x23f3…3adc",
    "Address": "0x23f379331d5b3e7c6a304c5a54f0848416393adc",
    "Followers": 135,
    "Net Worth": "$6,622",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 78,
    "Lepton": 0.73
  },
  {
    "Username": "khizar007",
    "Address": "0xf42b1a25d903489c46f74c79a4cd1c22330d231d",
    "Followers": 93,
    "Net Worth": "$6,374",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 69,
    "Lepton": 0.723
  },
  {
    "Username": "watashiwanim",
    "Address": "0x59cf5c5d3d9ecedc2489a1184cc29494d0dd23ea",
    "Followers": 211,
    "Net Worth": "$6,293",
    "Boson Weight": 6,
    "Lepton Weight": 9,
    "Boson": 91,
    "Lepton": 0.721
  },
  {
    "Username": "adjey",
    "Address": "0x29cc59fafa659beeb430402ca4ee5634ce6c67af",
    "Followers": 423,
    "Net Worth": "$6,221",
    "Boson Weight": 8,
    "Lepton Weight": 9,
    "Boson": 114,
    "Lepton": 0.719
  },
  {
    "Username": "bera",
    "Address": "0x8e3eeaa5c95273c43bad8ff95569bd0732296acd",
    "Followers": 322,
    "Net Worth": "$6,227",
    "Boson Weight": 7,
    "Lepton Weight": 9,
    "Boson": 104,
    "Lepton": 0.719
  },
  {
    "Username": "r00t",
    "Address": "0x79751b632155edb146521859541c4a45e24849e3",
    "Followers": 416,
    "Net Worth": "$6,215",
    "Boson Weight": 7,
    "Lepton Weight": 9,
    "Boson": 114,
    "Lepton": 0.719
  },
  {
    "Username": "0xcfe8…8deb",
    "Address": "0xcfe871a19085b18c2d57d0810a2b43f027c68deb",
    "Followers": 8,
    "Net Worth": "$6,082",
    "Boson Weight": 2,
    "Lepton Weight": 9,
    "Boson": 30,
    "Lepton": 0.715
  },
  {
    "Username": "0xd072…b4d6",
    "Address": "0xd072fd519d8e87bd2222c48d2ec5be6fc322b4d6",
    "Followers": 838,
    "Net Worth": "$6,033",
    "Boson Weight": 9,
    "Lepton Weight": 9,
    "Boson": 144,
    "Lepton": 0.713
  },
  {
    "Username": "mor3n",
    "Address": "0x7ef8d86cce3e3b77979c8e34dcac201c8ae7617a",
    "Followers": 473,
    "Net Worth": "$6,001",
    "Boson Weight": 8,
    "Lepton Weight": 9,
    "Boson": 119,
    "Lepton": 0.712
  },
  {
    "Username": "DYORme",
    "Address": "0x5fa18f60c6e4a08493abd3a4da7b02e20b0cb2f9",
    "Followers": 893,
    "Net Worth": "$5,971",
    "Boson Weight": 10,
    "Lepton Weight": 9,
    "Boson": 147,
    "Lepton": 0.712
  },
  {
    "Username": "MulticoinCapit",
    "Address": "0x1d309aa3b2e470911508b9ec6bf12a029ae7729c",
    "Followers": 278,
    "Net Worth": "$5,981",
    "Boson Weight": 7,
    "Lepton Weight": 9,
    "Boson": 99,
    "Lepton": 0.712
  },
  {
    "Username": "Boomer",
    "Address": "0xd91c6f109ba732b8a16edf920d8491fdb05935db",
    "Followers": 158,
    "Net Worth": "$5,946",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 82,
    "Lepton": 0.711
  },
  {
    "Username": "firstbtc",
    "Address": "0xd7c92712c4ad5e73f3fa3ae9d8a8ef29a46393aa",
    "Followers": 154,
    "Net Worth": "$5,945",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 82,
    "Lepton": 0.711
  },
  {
    "Username": "owl155",
    "Address": "0x04e19eed5fd3f2402f0f7e95425c498f4c62d02e",
    "Followers": 133,
    "Net Worth": "$5,932",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 78,
    "Lepton": 0.71
  },
  {
    "Username": "Zone",
    "Address": "0x430952fe84eba92645b6d897d161369888423779",
    "Followers": 164,
    "Net Worth": "$5,917",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 83,
    "Lepton": 0.71
  },
  {
    "Username": "hunteriv",
    "Address": "0x7f825692dfae7107d16bf4e2071fe06b1bc72297",
    "Followers": 101,
    "Net Worth": "$5,909",
    "Boson Weight": 5,
    "Lepton Weight": 9,
    "Boson": 71,
    "Lepton": 0.71
  },
  {
    "Username": "0x1b3d…67ee",
    "Address": "0x1b3d322f18f463b691d55d022c505ca8d02167ee",
    "Followers": 55,
    "Net Worth": "$5,877",
    "Boson Weight": 4,
    "Lepton Weight": 9,
    "Boson": 58,
    "Lepton": 0.709
  },
  {
    "Username": "EvilGreen",
    "Address": "0x8776d9c1aae452c32256f0709c9b9cfa3a1f9b07",
    "Followers": 290,
    "Net Worth": "$5,831",
    "Boson Weight": 7,
    "Lepton Weight": 9,
    "Boson": 101,
    "Lepton": 0.707
  },
  {
    "Username": "Brayan1987",
    "Address": "0x4995d9170046518e3d2a3cc2d27d8b669347fa60",
    "Followers": 299,
    "Net Worth": "$5,761",
    "Boson Weight": 7,
    "Lepton Weight": 9,
    "Boson": 102,
    "Lepton": 0.705
  },
  {
    "Username": "vhungcc",
    "Address": "0x563d27dd2e9f36157c658501222a7cb23f690c28",
    "Followers": "2,537",
    "Net Worth": "$5,704",
    "Boson Weight": 14,
    "Lepton Weight": 9,
    "Boson": 208,
    "Lepton": 0.703
  },
  {
    "Username": "Binance_CZ",
    "Address": "0xbdb5817349fa8f294ec0f0d2aff3eb4cd67914cf",
    "Followers": 459,
    "Net Worth": "$5,669",
    "Boson Weight": 8,
    "Lepton Weight": 9,
    "Boson": 118,
    "Lepton": 0.702
  },
  {
    "Username": "0xd2c9…8f07",
    "Address": "0xd2c9e843ce721a1ae3f54b13ca4eed4b068c8f07",
    "Followers": 80,
    "Net Worth": "$5,626",
    "Boson Weight": 4,
    "Lepton Weight": 9,
    "Boson": 66,
    "Lepton": 0.701
  },
  {
    "Username": "elgreen",
    "Address": "0x8da271715786bfe4d4b74a87bb99a842e9a95d53",
    "Followers": 621,
    "Net Worth": "$5,589",
    "Boson Weight": 9,
    "Lepton Weight": 9,
    "Boson": 130,
    "Lepton": 0.7
  },
  {
    "Username": "0xf843…cf9c",
    "Address": "0xf843b85a9abc591624b308f56cc72d7402ffcf9c",
    "Followers": 45,
    "Net Worth": "$5,545",
    "Boson Weight": 4,
    "Lepton Weight": 9,
    "Boson": 54,
    "Lepton": 0.699
  },
  {
    "Username": "r4keta",
    "Address": "0x0e07ecb7ea74b53f41a3c82b9ff7323512866ce8",
    "Followers": 801,
    "Net Worth": "$5,436",
    "Boson Weight": 9,
    "Lepton Weight": 9,
    "Boson": 142,
    "Lepton": 0.695
  },
  {
    "Username": "0x2ad3…04dc",
    "Address": "0x2ad3046d1b4e2bbc6827f09522dab20bce1104dc",
    "Followers": 521,
    "Net Worth": "$5,418",
    "Boson Weight": 8,
    "Lepton Weight": 9,
    "Boson": 123,
    "Lepton": 0.694
  },
  {
    "Username": "0xd83d…6daf",
    "Address": "0xd83db2797cdfdd6fb09ed36b483ed12c3de46daf",
    "Followers": 65,
    "Net Worth": "$5,195",
    "Boson Weight": 4,
    "Lepton Weight": 8,
    "Boson": 61,
    "Lepton": 0.687
  },
  {
    "Username": "supernova",
    "Address": "0x512e1c302d1e9ad0a80f5a54c82ab0fda1a2813b",
    "Followers": 241,
    "Net Worth": "$5,258",
    "Boson Weight": 6,
    "Lepton Weight": 9,
    "Boson": 95,
    "Lepton": 0.689
  },
  {
    "Username": "ingabd",
    "Address": "0xe77428ce19ad1ad1c7f85fe86119793520b48e81",
    "Followers": 370,
    "Net Worth": "$5,104",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 109,
    "Lepton": 0.684
  },
  {
    "Username": "Jordan",
    "Address": "0x189589abc6794f1c9b385099849c49598e987a68",
    "Followers": 1,
    "Net Worth": "$4,955",
    "Boson Weight": 1,
    "Lepton Weight": 8,
    "Boson": 15,
    "Lepton": 0.679
  },
  {
    "Username": "elonmask",
    "Address": "0x3df10334531a3c86590245e12be0104dd3459159",
    "Followers": 390,
    "Net Worth": "$4,900",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 111,
    "Lepton": 0.677
  },
  {
    "Username": "Sandorath",
    "Address": "0xf127a02ff5b21b25e9f939dfafebb352c81e70d2",
    "Followers": 333,
    "Net Worth": "$4,863",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 106,
    "Lepton": 0.676
  },
  {
    "Username": "GarryCrypto",
    "Address": "0x9b62a7ed23e6e2822ee8045694ef0db9eb099cc7",
    "Followers": 247,
    "Net Worth": "$4,805",
    "Boson Weight": 6,
    "Lepton Weight": 8,
    "Boson": 96,
    "Lepton": 0.674
  },
  {
    "Username": "0x5244…acc2",
    "Address": "0x5244575d81bfc1d4431d5d46393928b75ea3acc2",
    "Followers": 330,
    "Net Worth": "$4,768",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 105,
    "Lepton": 0.673
  },
  {
    "Username": "rollup",
    "Address": "0xda4e91e794ac04cdf8250556abccd28799f34ed6",
    "Followers": 281,
    "Net Worth": "$4,706",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 100,
    "Lepton": 0.67
  },
  {
    "Username": "0x87fd…6366",
    "Address": "0x87fd5b35d4dbfacfe47f72ebc77313aeeb346366",
    "Followers": 43,
    "Net Worth": "$4,636",
    "Boson Weight": 4,
    "Lepton Weight": 8,
    "Boson": 53,
    "Lepton": 0.668
  },
  {
    "Username": "Reza9_4",
    "Address": "0x278e2ddc31ee9fe61e73b10ee5ce5fbf5a401049",
    "Followers": "3,105",
    "Net Worth": "$4,628",
    "Boson Weight": 15,
    "Lepton Weight": 8,
    "Boson": 222,
    "Lepton": 0.668
  },
  {
    "Username": "biconomy",
    "Address": "0x1084577b94a412ac182434b37d3b07b3319580d5",
    "Followers": "1,219",
    "Net Worth": "$4,599",
    "Boson Weight": 11,
    "Lepton Weight": 8,
    "Boson": 163,
    "Lepton": 0.667
  },
  {
    "Username": "0x5853…e401",
    "Address": "",
    "Followers": "3,098",
    "Net Worth": "$4,583",
    "Boson Weight": 15,
    "Lepton Weight": 8,
    "Boson": 222,
    "Lepton": 0.666
  },
  {
    "Username": "mamad",
    "Address": "0x4ac756c7afed95cd7700124dddc0ecc3eb347bb7",
    "Followers": 487,
    "Net Worth": "$4,605",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 120,
    "Lepton": 0.667
  },
  {
    "Username": "garins",
    "Address": "0x30620715bcf265773b2973eff4293cee0bb1b774",
    "Followers": 50,
    "Net Worth": "$4,479",
    "Boson Weight": 4,
    "Lepton Weight": 8,
    "Boson": 56,
    "Lepton": 0.662
  },
  {
    "Username": "MXTHVll",
    "Address": "0x7b968dd081e6d11b850c9f841337a77a13b10e66",
    "Followers": 544,
    "Net Worth": "$4,492",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 124,
    "Lepton": 0.663
  },
  {
    "Username": "xNxNx",
    "Address": "0xa18453f015034d42d6c8169c3d88d2a01f52a180",
    "Followers": 104,
    "Net Worth": "$4,427",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 72,
    "Lepton": 0.66
  },
  {
    "Username": "mehdiakrami",
    "Address": "0x2fd81617ac20c54109f1b1b6b08c5b7e74d56210",
    "Followers": "2,576",
    "Net Worth": "$4,439",
    "Boson Weight": 14,
    "Lepton Weight": 8,
    "Boson": 209,
    "Lepton": 0.661
  },
  {
    "Username": "Killa007",
    "Address": "0xb1d248e9550ac090c9eb25098e27037b118a1de0",
    "Followers": 286,
    "Net Worth": "$4,282",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 100,
    "Lepton": 0.655
  },
  {
    "Username": "bankless",
    "Address": "0xa5cb97830f353683b90d872a0d4409faf4e9c428",
    "Followers": 341,
    "Net Worth": "$4,362",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 106,
    "Lepton": 0.658
  },
  {
    "Username": "novy",
    "Address": "0xc05aa471f3bf88c940812d17a7630f620f003778",
    "Followers": 15,
    "Net Worth": "$4,345",
    "Boson Weight": 2,
    "Lepton Weight": 8,
    "Boson": 38,
    "Lepton": 0.657
  },
  {
    "Username": "p10neer",
    "Address": "0xb0b1babf19dcefbeb9018288203a61f44a4c66df",
    "Followers": 282,
    "Net Worth": "$4,287",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 100,
    "Lepton": 0.655
  },
  {
    "Username": "0x0991…c26a",
    "Address": "0x0991892b02a2b752ad68f2b01dd8457f9c22c26a",
    "Followers": 12,
    "Net Worth": "$4,263",
    "Boson Weight": 2,
    "Lepton Weight": 8,
    "Boson": 35,
    "Lepton": 0.654
  },
  {
    "Username": "0xSupernova",
    "Address": "0xbaf88b32025aeaefee010013da46098382475ddc",
    "Followers": 230,
    "Net Worth": "$4,244",
    "Boson Weight": 6,
    "Lepton Weight": 8,
    "Boson": 93,
    "Lepton": 0.653
  },
  {
    "Username": "Yarcheg",
    "Address": "0x279819776945fd04c5ab3017faaff2c597f61f61",
    "Followers": 676,
    "Net Worth": "$4,240",
    "Boson Weight": 9,
    "Lepton Weight": 8,
    "Boson": 134,
    "Lepton": 0.653
  },
  {
    "Username": "taiscrew",
    "Address": "0x2c41ac46dbf706dc7be77dc45d759a5cc1275537",
    "Followers": 160,
    "Net Worth": "$4,220",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 83,
    "Lepton": 0.652
  },
  {
    "Username": "Jimmyy",
    "Address": "0x44ff5c2b020a1316c7964d1484c27ebab066743c",
    "Followers": "3,440",
    "Net Worth": "$4,190",
    "Boson Weight": 15,
    "Lepton Weight": 8,
    "Boson": 230,
    "Lepton": 0.651
  },
  {
    "Username": "0xfcf2…373f",
    "Address": "0xfcf2904e00e929154633a774bd0e4d98cd03373f",
    "Followers": 42,
    "Net Worth": "$4,136",
    "Boson Weight": 3,
    "Lepton Weight": 8,
    "Boson": 53,
    "Lepton": 0.649
  },
  {
    "Username": "thai",
    "Address": "0xc938992123c9d67b30155b99978dceeb12f0e93f",
    "Followers": 265,
    "Net Worth": "$4,017",
    "Boson Weight": 6,
    "Lepton Weight": 8,
    "Boson": 98,
    "Lepton": 0.644
  },
  {
    "Username": "futeh",
    "Address": "0x0e29304fee49a7f3bfb4bd0c0c3d574597dc2bdf",
    "Followers": 58,
    "Net Worth": "$3,855",
    "Boson Weight": 4,
    "Lepton Weight": 8,
    "Boson": 59,
    "Lepton": 0.638
  },
  {
    "Username": "chenxiao_1031",
    "Address": "0xb8a883325e4da8a222e9e2d06a1762115b6ba7b3",
    "Followers": 153,
    "Net Worth": "$3,834",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 82,
    "Lepton": 0.637
  },
  {
    "Username": "0x44e434b0eb835",
    "Address": "0x44e434b0eb83534b697f9c77ef11fb981b637def",
    "Followers": 443,
    "Net Worth": "$3,791",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 116,
    "Lepton": 0.635
  },
  {
    "Username": "0xc000…4683",
    "Address": "0xc000d2f1a360fbd58e4461c0ef960f0cb7984683",
    "Followers": 34,
    "Net Worth": "$3,787",
    "Boson Weight": 3,
    "Lepton Weight": 8,
    "Boson": 49,
    "Lepton": 0.635
  },
  {
    "Username": "ukrcargo",
    "Address": "0xde6baa64154b19c4475cfa2dcb4cf27dbbcca36b",
    "Followers": 97,
    "Net Worth": "$3,773",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 70,
    "Lepton": 0.634
  },
  {
    "Username": "GUILHOU",
    "Address": "0x29e83a72648fda0413c02f08563e5a2c87e4376a",
    "Followers": 142,
    "Net Worth": "$3,741",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 80,
    "Lepton": 0.633
  },
  {
    "Username": "0x299d…916c",
    "Address": "0x299d1f9bd5735393c99ac30fbd07d8591c28916c",
    "Followers": 0,
    "Net Worth": "$3,769",
    "Boson Weight": 0,
    "Lepton Weight": 8,
    "Boson": 0,
    "Lepton": 0.634
  },
  {
    "Username": "MS_Takeshi",
    "Address": "0x6fdcb1e36df10a8f7f9707b635c1907ceb8841ba",
    "Followers": 809,
    "Net Worth": "$3,705",
    "Boson Weight": 9,
    "Lepton Weight": 8,
    "Boson": 142,
    "Lepton": 0.632
  },
  {
    "Username": "0xef06…b5ef",
    "Address": "",
    "Followers": 418,
    "Net Worth": "$3,688",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 114,
    "Lepton": 0.631
  },
  {
    "Username": "Nord2k",
    "Address": "0xeedc57a8a24f9fe798c79a7f9e0e95e93a470341",
    "Followers": 419,
    "Net Worth": "$3,667",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 114,
    "Lepton": 0.63
  },
  {
    "Username": "DElite",
    "Address": "0xc0c5192b1e3d6db0e6f86e481b12e577094b49a5",
    "Followers": 736,
    "Net Worth": "$3,659",
    "Boson Weight": 9,
    "Lepton Weight": 8,
    "Boson": 138,
    "Lepton": 0.63
  },
  {
    "Username": "floor",
    "Address": "0x9509ad9a025155857f9d788f34d30f52aa2db8ed",
    "Followers": 830,
    "Net Worth": "$3,633",
    "Boson Weight": 9,
    "Lepton Weight": 8,
    "Boson": 143,
    "Lepton": 0.628
  },
  {
    "Username": "0x4b1d…c759",
    "Address": "",
    "Followers": 18,
    "Net Worth": "$3,591",
    "Boson Weight": 3,
    "Lepton Weight": 8,
    "Boson": 40,
    "Lepton": 0.627
  },
  {
    "Username": "sunrise77",
    "Address": "0xf325610a2f9a0fb8bb92b472b8904349ea65c2ed",
    "Followers": 300,
    "Net Worth": "$3,585",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 102,
    "Lepton": 0.626
  },
  {
    "Username": "Thorin404",
    "Address": "0x651817b6b7464b61c6c6ef87da2bc84ee0e64377",
    "Followers": 505,
    "Net Worth": "$3,556",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 121,
    "Lepton": 0.625
  },
  {
    "Username": "0x00a7…5f48",
    "Address": "",
    "Followers": 13,
    "Net Worth": "$3,539",
    "Boson Weight": 2,
    "Lepton Weight": 8,
    "Boson": 36,
    "Lepton": 0.624
  },
  {
    "Username": "TonLiw",
    "Address": "0xdb9fbc7350042c249c641a156f504eca1f02a63d",
    "Followers": 57,
    "Net Worth": "$3,470",
    "Boson Weight": 4,
    "Lepton Weight": 8,
    "Boson": 59,
    "Lepton": 0.621
  },
  {
    "Username": "inam",
    "Address": "0x347113c4ec42f6610b5348e908ffea04f7f4f651",
    "Followers": 415,
    "Net Worth": "$3,475",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 114,
    "Lepton": 0.621
  },
  {
    "Username": "Borgir",
    "Address": "0x18f25ecf2d37dab8c7d480cbaa1450e89eaedb9e",
    "Followers": 524,
    "Net Worth": "$3,457",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 123,
    "Lepton": 0.621
  },
  {
    "Username": "Bubs",
    "Address": "0x1fb10e585408ce8788c953acd80302aee664b241",
    "Followers": 265,
    "Net Worth": "$3,333",
    "Boson Weight": 6,
    "Lepton Weight": 8,
    "Boson": 98,
    "Lepton": 0.615
  },
  {
    "Username": "YSL_777",
    "Address": "0xf613bdee12ebf8b204d21c4ab57793f0ad47c607",
    "Followers": 447,
    "Net Worth": "$3,417",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 117,
    "Lepton": 0.619
  },
  {
    "Username": "Gombosh",
    "Address": "0xdf883664b414c4ef203193010f9f372606ab2a5a",
    "Followers": 185,
    "Net Worth": "$3,376",
    "Boson Weight": 6,
    "Lepton Weight": 8,
    "Boson": 87,
    "Lepton": 0.617
  },
  {
    "Username": "triplesix",
    "Address": "0xe59f4a315aa1d99bdd32d999bd265aee215c6cf6",
    "Followers": 462,
    "Net Worth": "$3,355",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 118,
    "Lepton": 0.616
  },
  {
    "Username": "qphome",
    "Address": "0x907b332f4d2190027b115224a0ba7c8ef9f30273",
    "Followers": 136,
    "Net Worth": "$3,304",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 78,
    "Lepton": 0.614
  },
  {
    "Username": "SafeMoon",
    "Address": "0x75df7a6530dc02b24c316c9961efea812ab27084",
    "Followers": 375,
    "Net Worth": "$3,299",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 110,
    "Lepton": 0.613
  },
  {
    "Username": "0x71a4…4620",
    "Address": "",
    "Followers": 8,
    "Net Worth": "$3,250",
    "Boson Weight": 2,
    "Lepton Weight": 8,
    "Boson": 30,
    "Lepton": 0.611
  },
  {
    "Username": "Konstantin",
    "Address": "0x39af6952f2fbe751a10f28a63f9f57d65f64e3fa",
    "Followers": 123,
    "Net Worth": "$3,239",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 76,
    "Lepton": 0.611
  },
  {
    "Username": "ICBM",
    "Address": "0xe666830b4af8419167e108f5a4601a249e98da16",
    "Followers": 436,
    "Net Worth": "$3,236",
    "Boson Weight": 8,
    "Lepton Weight": 8,
    "Boson": 116,
    "Lepton": 0.611
  },
  {
    "Username": "0xc585…a7b3",
    "Address": "",
    "Followers": 133,
    "Net Worth": "$3,234",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 78,
    "Lepton": 0.61
  },
  {
    "Username": "0x0d5e…c1fb",
    "Address": "",
    "Followers": 46,
    "Net Worth": "$3,226",
    "Boson Weight": 4,
    "Lepton Weight": 8,
    "Boson": 55,
    "Lepton": 0.61
  },
  {
    "Username": "alin",
    "Address": "0x68be8d7053181e4968063df4aad06975751ad75e",
    "Followers": 36,
    "Net Worth": "$3,221",
    "Boson Weight": 3,
    "Lepton Weight": 8,
    "Boson": 50,
    "Lepton": 0.61
  },
  {
    "Username": "Valtntina",
    "Address": "0x06b5034bb3f07321c4a1daaceb6efd7626087208",
    "Followers": 382,
    "Net Worth": "$3,214",
    "Boson Weight": 7,
    "Lepton Weight": 8,
    "Boson": 111,
    "Lepton": 0.609
  },
  {
    "Username": "Mitya83",
    "Address": "0x69c12e2ca4886e7e5945cdd9b2be2e5fd89532bb",
    "Followers": 112,
    "Net Worth": "$3,194",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 73,
    "Lepton": 0.609
  },
  {
    "Username": "bod9",
    "Address": "0x14c255122eb89e2dd7d16a449bd8a6e7328dabf3",
    "Followers": 30,
    "Net Worth": "$3,164",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 47,
    "Lepton": 0.607
  },
  {
    "Username": "Cryptorika",
    "Address": "0xf1d75e75e375d0656bedad4c0298f4c4668b3e21",
    "Followers": 143,
    "Net Worth": "$3,171",
    "Boson Weight": 5,
    "Lepton Weight": 8,
    "Boson": 80,
    "Lepton": 0.607
  },
  {
    "Username": "0x1266…4a78",
    "Address": "",
    "Followers": 67,
    "Net Worth": "$3,131",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 62,
    "Lepton": 0.606
  },
  {
    "Username": "steadybombibi",
    "Address": "0xda31721fd25865b00d729e31bcbf377f1e15c495",
    "Followers": 687,
    "Net Worth": "$3,110",
    "Boson Weight": 9,
    "Lepton Weight": 7,
    "Boson": 135,
    "Lepton": 0.604
  },
  {
    "Username": "0x3a06…3a2d",
    "Address": "",
    "Followers": 76,
    "Net Worth": "$3,101",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 65,
    "Lepton": 0.604
  },
  {
    "Username": "0x1307…b54e",
    "Address": "",
    "Followers": 12,
    "Net Worth": "$3,073",
    "Boson Weight": 2,
    "Lepton Weight": 7,
    "Boson": 35,
    "Lepton": 0.603
  },
  {
    "Username": "giganticbirth",
    "Address": "0x29e773a798730070b341dc09e58d224fa3a55eb2",
    "Followers": 460,
    "Net Worth": "$3,034",
    "Boson Weight": 8,
    "Lepton Weight": 7,
    "Boson": 118,
    "Lepton": 0.601
  },
  {
    "Username": "0x9433…f4df",
    "Address": "",
    "Followers": "1,106",
    "Net Worth": "$3,021",
    "Boson Weight": 10,
    "Lepton Weight": 7,
    "Boson": 158,
    "Lepton": 0.6
  },
  {
    "Username": "Metaguild",
    "Address": "0xc68c8452be34032ab999787796ae0faaf071e054",
    "Followers": 235,
    "Net Worth": "$3,016",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 94,
    "Lepton": 0.6
  },
  {
    "Username": "0xa793…2552",
    "Address": "",
    "Followers": 535,
    "Net Worth": "$3,015",
    "Boson Weight": 8,
    "Lepton Weight": 7,
    "Boson": 124,
    "Lepton": 0.6
  },
  {
    "Username": "Antinatalist",
    "Address": "0xb7396d65ac352471dbbeffc94baf8ef3a7251a6b",
    "Followers": 184,
    "Net Worth": "$2,992",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 87,
    "Lepton": 0.599
  },
  {
    "Username": "0x3326…f1d8",
    "Address": "",
    "Followers": 40,
    "Net Worth": "$2,981",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 52,
    "Lepton": 0.598
  },
  {
    "Username": "Rs99999",
    "Address": "0x184e61d0fa5f05a22867bb3ec416b056db16490c",
    "Followers": 69,
    "Net Worth": "$2,908",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 63,
    "Lepton": 0.594
  },
  {
    "Username": "anrd04",
    "Address": "0x500e4170fce824fcdc62d5e0d1f8e3a202634deb",
    "Followers": 539,
    "Net Worth": "$2,914",
    "Boson Weight": 8,
    "Lepton Weight": 7,
    "Boson": 124,
    "Lepton": 0.595
  },
  {
    "Username": "Topinvestor",
    "Address": "0x24878dc8aedeaf05c7097bbfe65a97c2fc13d15e",
    "Followers": "1,745",
    "Net Worth": "$2,904",
    "Boson Weight": 12,
    "Lepton Weight": 7,
    "Boson": 184,
    "Lepton": 0.594
  },
  {
    "Username": "AnnaVr",
    "Address": "0x67586cb5bcab1db5949d0638712d364a74de4cc1",
    "Followers": "1,346",
    "Net Worth": "$2,906",
    "Boson Weight": 11,
    "Lepton Weight": 7,
    "Boson": 168,
    "Lepton": 0.594
  },
  {
    "Username": "sixfigures",
    "Address": "0x7722c3fc2fbb53d0aea6bb80c8bd44a9b0dd1def",
    "Followers": 66,
    "Net Worth": "$2,877",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 62,
    "Lepton": 0.593
  },
  {
    "Username": "0x4440…91e4",
    "Address": "0x4440e5048224c2acf1bf3617ec78614f5bf391e4",
    "Followers": "1,035",
    "Net Worth": "$2,867",
    "Boson Weight": 10,
    "Lepton Weight": 7,
    "Boson": 154,
    "Lepton": 0.592
  },
  {
    "Username": "shalom",
    "Address": "0x55cfbb18b3e35b05c20f9056d7b79cfc3f359526",
    "Followers": 466,
    "Net Worth": "$2,794",
    "Boson Weight": 8,
    "Lepton Weight": 7,
    "Boson": 118,
    "Lepton": 0.589
  },
  {
    "Username": "Ftylmz",
    "Address": "0xed5a42f58d5dbde7e0cc122416a3540de6ccc6c3",
    "Followers": 215,
    "Net Worth": "$2,738",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 91,
    "Lepton": 0.586
  },
  {
    "Username": "Aldebo",
    "Address": "0x18c44273a6e367f0bb49385379c6364a3c1dbc81",
    "Followers": 63,
    "Net Worth": "$2,727",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 61,
    "Lepton": 0.585
  },
  {
    "Username": "0xae50…f414",
    "Address": "0xae50a4dd72e4efc855f6380bd2c9334bf41af414",
    "Followers": 35,
    "Net Worth": "$2,703",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 50,
    "Lepton": 0.584
  },
  {
    "Username": "0x66e1…29a8",
    "Address": "0x66e1df8db9b0521d41d7eb30464a5281f71b29a8",
    "Followers": 12,
    "Net Worth": "$2,705",
    "Boson Weight": 2,
    "Lepton Weight": 7,
    "Boson": 35,
    "Lepton": 0.584
  },
  {
    "Username": "maryy",
    "Address": "0xcd09c60a826b735265d6b9bea068097324a39943",
    "Followers": 179,
    "Net Worth": "$2,672",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 86,
    "Lepton": 0.582
  },
  {
    "Username": "slippy87",
    "Address": "0x5b02da8420dd9f77a33c6e2b22dc87fe22c17a30",
    "Followers": 765,
    "Net Worth": "$2,657",
    "Boson Weight": 9,
    "Lepton Weight": 7,
    "Boson": 139,
    "Lepton": 0.581
  },
  {
    "Username": "Wrath",
    "Address": "0x74ae37b023f411ff80f3a98d1f4680f38f029e57",
    "Followers": 155,
    "Net Worth": "$2,654",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 82,
    "Lepton": 0.581
  },
  {
    "Username": "evilmonkey",
    "Address": "0xf0cc5a93344d5c0e8134c099e17ac898d232c5d5",
    "Followers": 39,
    "Net Worth": "$2,633",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 52,
    "Lepton": 0.58
  },
  {
    "Username": "0xda46…97ae",
    "Address": "0xda46e3427ecdac2afd516814d7aaf0758d2a97ae",
    "Followers": 35,
    "Net Worth": "$2,610",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 50,
    "Lepton": 0.579
  },
  {
    "Username": "NFTNews",
    "Address": "0x1809754df5ac133a1cc7d4eaf45ed38cf9b39ec8",
    "Followers": 106,
    "Net Worth": "$2,579",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 72,
    "Lepton": 0.577
  },
  {
    "Username": "Numan_Erdem",
    "Address": "0x27e9f3d1354a735a37a564fd2a9dd905c944a08e",
    "Followers": 166,
    "Net Worth": "$2,530",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 84,
    "Lepton": 0.574
  },
  {
    "Username": "yuva",
    "Address": "0xdc9c5e34959ec3643af1e1d34a83d6b251aab1ef",
    "Followers": 604,
    "Net Worth": "$2,528",
    "Boson Weight": 8,
    "Lepton Weight": 7,
    "Boson": 129,
    "Lepton": 0.574
  },
  {
    "Username": "homlin",
    "Address": "0x60b73717cf711f312a621f19bd76ac138da5af76",
    "Followers": 285,
    "Net Worth": "$2,518",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 100,
    "Lepton": 0.573
  },
  {
    "Username": "0x05a7…99fe",
    "Address": "0x05a7eb929209f5c1f2f08dd087b64bb1beba99fe",
    "Followers": 193,
    "Net Worth": "$2,511",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 88,
    "Lepton": 0.573
  },
  {
    "Username": "Dargel",
    "Address": "0x96c8702b3bec2033d23085357883a9fe3ee74ba7",
    "Followers": 275,
    "Net Worth": "$2,483",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 99,
    "Lepton": 0.571
  },
  {
    "Username": "0x849b…8682",
    "Address": "0x849b75d925ce7534d51c2cb1257c7fc379f68682",
    "Followers": "1,638",
    "Net Worth": "$2,464",
    "Boson Weight": 12,
    "Lepton Weight": 7,
    "Boson": 180,
    "Lepton": 0.57
  },
  {
    "Username": "ONLY9KBUY",
    "Address": "0x32ff90bc3fa85d87e982d5b384a453bed65a799d",
    "Followers": 224,
    "Net Worth": "$2,357",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 93,
    "Lepton": 0.564
  },
  {
    "Username": "ThomaShelby",
    "Address": "0x973b79656f9a2b6d3f9b04e93f3c340c9f7b4c6c",
    "Followers": 62,
    "Net Worth": "$2,354",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 60,
    "Lepton": 0.564
  },
  {
    "Username": "Kirigan",
    "Address": "0xb3cc5f223b795d17e0dd6f3daff06c13f47354e4",
    "Followers": 225,
    "Net Worth": "$2,334",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 93,
    "Lepton": 0.563
  },
  {
    "Username": "Wallstreetape",
    "Address": "0xbae105f1650b570b795312236f82ad540bfbd9a0",
    "Followers": 97,
    "Net Worth": "$2,325",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 70,
    "Lepton": 0.562
  },
  {
    "Username": "m4e3",
    "Address": "0x45c5a552576f3c75f3789dffc2bba0276e7721e1",
    "Followers": 107,
    "Net Worth": "$2,298",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 72,
    "Lepton": 0.56
  },
  {
    "Username": "Holman",
    "Address": "0x31631f6e73d7bc446facc66e28ca24f14e218932",
    "Followers": 295,
    "Net Worth": "$2,284",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 101,
    "Lepton": 0.56
  },
  {
    "Username": "HappyG1lmore",
    "Address": "0x5e195e305abe770f0df0930758cfda519bed2901",
    "Followers": 181,
    "Net Worth": "$2,266",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 86,
    "Lepton": 0.558
  },
  {
    "Username": "minhngan",
    "Address": "0x4a5e200e870fa788321e3ecc776860a587f5cf23",
    "Followers": "2,337",
    "Net Worth": "$2,253",
    "Boson Weight": 13,
    "Lepton Weight": 7,
    "Boson": 202,
    "Lepton": 0.558
  },
  {
    "Username": "0x06b6…9015",
    "Address": "0x06b6b7f0b322e2894cfb4dc62d869a76c8a09015",
    "Followers": 25,
    "Net Worth": "$2,211",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 45,
    "Lepton": 0.555
  },
  {
    "Username": "0xa649…32b7",
    "Address": "0xa649e1841678ca774251cc92ea25cf0fddbc32b7",
    "Followers": 73,
    "Net Worth": "$2,198",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 64,
    "Lepton": 0.554
  },
  {
    "Username": "LuisBui",
    "Address": "0x9c365d7cf431df90dc9d839bd3bd4d09fa73cf16",
    "Followers": "1,434",
    "Net Worth": "$2,134",
    "Boson Weight": 11,
    "Lepton Weight": 7,
    "Boson": 172,
    "Lepton": 0.55
  },
  {
    "Username": "naver",
    "Address": "0x5b63124006840060898f47a21eb82c019289eab5",
    "Followers": 304,
    "Net Worth": "$2,120",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 102,
    "Lepton": 0.549
  },
  {
    "Username": "0xe4e0…2767",
    "Address": "0xe4e0f568287d1c8e4d48a1e0eba4ee703dad2767",
    "Followers": "1,015",
    "Net Worth": "$2,106",
    "Boson Weight": 10,
    "Lepton Weight": 7,
    "Boson": 153,
    "Lepton": 0.548
  },
  {
    "Username": "brema",
    "Address": "0xf30eb06b38c365a05385989218bb3493e0db584a",
    "Followers": 154,
    "Net Worth": "$2,092",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 82,
    "Lepton": 0.547
  },
  {
    "Username": "creong1221",
    "Address": "0x4500998b58ec4fa2bdd9c0b7cc6971702307c1bf",
    "Followers": "1,506",
    "Net Worth": "$2,078",
    "Boson Weight": 11,
    "Lepton Weight": 7,
    "Boson": 175,
    "Lepton": 0.547
  },
  {
    "Username": "0x0f1a…83b6",
    "Address": "0x0f1a4d5d6bc397326462864f5dd578ddb14a83b6",
    "Followers": 285,
    "Net Worth": "$2,077",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 100,
    "Lepton": 0.546
  },
  {
    "Username": "Mishiro",
    "Address": "0xea80f95593362de319b7ddba522d7ae6de9c2a1a",
    "Followers": 96,
    "Net Worth": "$2,077",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 70,
    "Lepton": 0.546
  },
  {
    "Username": "0xa7a8…78a8",
    "Address": "",
    "Followers": 66,
    "Net Worth": "$2,049",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 62,
    "Lepton": 0.545
  },
  {
    "Username": "0x8cd8…5833",
    "Address": "",
    "Followers": 12,
    "Net Worth": "$2,048",
    "Boson Weight": 2,
    "Lepton Weight": 7,
    "Boson": 35,
    "Lepton": 0.545
  },
  {
    "Username": "AVAX",
    "Address": "0xf3f3b0f937d222ad7ce80b8be6fcf12517e245b0",
    "Followers": 242,
    "Net Worth": "$2,029",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 95,
    "Lepton": 0.543
  },
  {
    "Username": "zerry",
    "Address": "0x8a53c7a940307225682f70880e7dbb827f3a3839",
    "Followers": 41,
    "Net Worth": "$2,042",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 53,
    "Lepton": 0.544
  },
  {
    "Username": "0x41b4…f14e",
    "Address": "",
    "Followers": 25,
    "Net Worth": "$2,041",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 45,
    "Lepton": 0.544
  },
  {
    "Username": "INSTA",
    "Address": "0x2498a726fd198892ea031a17f41da7f5574480d6",
    "Followers": 289,
    "Net Worth": "$2,040",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 101,
    "Lepton": 0.544
  },
  {
    "Username": "0x0d2c…5b8e",
    "Address": "",
    "Followers": 47,
    "Net Worth": "$2,029",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 55,
    "Lepton": 0.543
  },
  {
    "Username": "Hitsezonu",
    "Address": "0xbd2117c74ea26b75ee4593d4e4622430c91a8e34",
    "Followers": 54,
    "Net Worth": "$2,022",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 58,
    "Lepton": 0.543
  },
  {
    "Username": "Acer",
    "Address": "0x7a789b0024e92b25c041ee236fe91796c8445934",
    "Followers": 287,
    "Net Worth": "$2,022",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 101,
    "Lepton": 0.543
  },
  {
    "Username": "B1nance",
    "Address": "0xf7f0116a51fe6c7623a6ec2491a15a4cf92f324e",
    "Followers": 92,
    "Net Worth": "$2,013",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 69,
    "Lepton": 0.542
  },
  {
    "Username": "Lebowski",
    "Address": "0x2e7796a8cf69b458a79963b35545f1744e682fec",
    "Followers": 257,
    "Net Worth": "$2,013",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 97,
    "Lepton": 0.542
  },
  {
    "Username": "0x64e3…1171",
    "Address": "",
    "Followers": 111,
    "Net Worth": "$2,022",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 73,
    "Lepton": 0.543
  },
  {
    "Username": "Jackmeng",
    "Address": "0x626125d30a86d3b069dcc3a72c81e4174a4eaf90",
    "Followers": "1,471",
    "Net Worth": "$1,973",
    "Boson Weight": 11,
    "Lepton Weight": 7,
    "Boson": 173,
    "Lepton": 0.539
  },
  {
    "Username": "Alesam",
    "Address": "0x4961f2d7eac878af3fe4f32f24ee47aa594b8fd0",
    "Followers": 210,
    "Net Worth": "$1,969",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 91,
    "Lepton": 0.539
  },
  {
    "Username": "0x0851…6faf",
    "Address": "",
    "Followers": 203,
    "Net Worth": "$1,963",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 90,
    "Lepton": 0.539
  },
  {
    "Username": "Ziniak",
    "Address": "0x1d08d15f8b2008643e39fd7f559ae749b1ea2378",
    "Followers": 161,
    "Net Worth": "$1,951",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 83,
    "Lepton": 0.538
  },
  {
    "Username": "Kite",
    "Address": "0x884fdaf0af78f474b1231d591770969ba81eee91",
    "Followers": 430,
    "Net Worth": "$1,935",
    "Boson Weight": 8,
    "Lepton Weight": 7,
    "Boson": 115,
    "Lepton": 0.537
  },
  {
    "Username": "vfarm09",
    "Address": "0xcf4228a8c37c277c91930260adc3799409c7c1d1",
    "Followers": 144,
    "Net Worth": "$1,927",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 80,
    "Lepton": 0.536
  },
  {
    "Username": "nik3e",
    "Address": "0x7465a22ba37d72ed2995be8d5e94ab1a39338f30",
    "Followers": 415,
    "Net Worth": "$1,920",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 114,
    "Lepton": 0.536
  },
  {
    "Username": "0xaba5…2413",
    "Address": "",
    "Followers": 50,
    "Net Worth": "$1,924",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 56,
    "Lepton": 0.536
  },
  {
    "Username": "0xb37b…386a",
    "Address": "",
    "Followers": 364,
    "Net Worth": "$1,916",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 109,
    "Lepton": 0.536
  },
  {
    "Username": "0xffc1…8839",
    "Address": "",
    "Followers": 149,
    "Net Worth": "$1,914",
    "Boson Weight": 5,
    "Lepton Weight": 7,
    "Boson": 81,
    "Lepton": 0.535
  },
  {
    "Username": "0x43c3…61f2",
    "Address": "",
    "Followers": "1,056",
    "Net Worth": "$1,902",
    "Boson Weight": 10,
    "Lepton Weight": 7,
    "Boson": 155,
    "Lepton": 0.535
  },
  {
    "Username": "NuttPS",
    "Address": "0x057561523e7819d19d7690eca2ff05a138fc3b4f",
    "Followers": 349,
    "Net Worth": "$1,866",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 107,
    "Lepton": 0.532
  },
  {
    "Username": "0eth",
    "Address": "0x7e95da20083bcb74fecec9c77966c7d376b5a0d0",
    "Followers": 836,
    "Net Worth": "$1,821",
    "Boson Weight": 9,
    "Lepton Weight": 7,
    "Boson": 144,
    "Lepton": 0.529
  },
  {
    "Username": "0xcf07…2e27",
    "Address": "",
    "Followers": 66,
    "Net Worth": "$1,832",
    "Boson Weight": 4,
    "Lepton Weight": 7,
    "Boson": 62,
    "Lepton": 0.53
  },
  {
    "Username": "0x28cb…63fb",
    "Address": "",
    "Followers": 22,
    "Net Worth": "$1,810",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 43,
    "Lepton": 0.528
  },
  {
    "Username": "Gluxapb",
    "Address": "0x89434c69dc4cc10dd7ffd75536b39548115cf339",
    "Followers": 342,
    "Net Worth": "$1,813",
    "Boson Weight": 7,
    "Lepton Weight": 7,
    "Boson": 107,
    "Lepton": 0.528
  },
  {
    "Username": "AntSim",
    "Address": "0x3a4985fcfa8ed8c84521cf86a5c04faa9810055f",
    "Followers": 199,
    "Net Worth": "$1,802",
    "Boson Weight": 6,
    "Lepton Weight": 7,
    "Boson": 89,
    "Lepton": 0.527
  },
  {
    "Username": "ragetrade",
    "Address": "0x9c24523833dc3e876ffb658b93a1d1c62fcacb1b",
    "Followers": 35,
    "Net Worth": "$1,790",
    "Boson Weight": 3,
    "Lepton Weight": 7,
    "Boson": 50,
    "Lepton": 0.527
  },
  {
    "Username": "yu3ver",
    "Address": "0x365f027f73d3d176619b2dd8ca0bdd7766bcf24d",
    "Followers": 121,
    "Net Worth": "$1,759",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 75,
    "Lepton": 0.524
  },
  {
    "Username": "0x812b…abd6",
    "Address": "",
    "Followers": 50,
    "Net Worth": "$1,777",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 56,
    "Lepton": 0.526
  },
  {
    "Username": "margolo",
    "Address": "0x7493dca4eb307a78d388b998eada53d6629cfa2a",
    "Followers": 53,
    "Net Worth": "$1,746",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 57,
    "Lepton": 0.523
  },
  {
    "Username": "LEV51",
    "Address": "0x3e9f44209e3217a81f7913a6cd910b9a379a7895",
    "Followers": 12,
    "Net Worth": "$1,726",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 35,
    "Lepton": 0.522
  },
  {
    "Username": "dework",
    "Address": "0xdb41f0d9186776fe853aef0de79897648b6c2a9d",
    "Followers": 159,
    "Net Worth": "$1,717",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 83,
    "Lepton": 0.521
  },
  {
    "Username": "Irzzzz",
    "Address": "0xeaa6a500543581753ef27d1def789458b3b102ad",
    "Followers": 60,
    "Net Worth": "$1,701",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 60,
    "Lepton": 0.52
  },
  {
    "Username": "0x8535…7f83",
    "Address": "",
    "Followers": 26,
    "Net Worth": "$1,699",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 45,
    "Lepton": 0.52
  },
  {
    "Username": "0xb941…20b9",
    "Address": "",
    "Followers": 143,
    "Net Worth": "$1,594",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 80,
    "Lepton": 0.511
  },
  {
    "Username": "Valerii",
    "Address": "0xa7c3d6ddc9e1e5addf3b907af7c14b56befbce41",
    "Followers": 140,
    "Net Worth": "$1,667",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 79,
    "Lepton": 0.517
  },
  {
    "Username": "0x2e56…ff6e",
    "Address": "",
    "Followers": 52,
    "Net Worth": "$1,669",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 57,
    "Lepton": 0.517
  },
  {
    "Username": "djuseppe",
    "Address": "0xaaf5db1894b374c54d8bf74b4f45aeafa1781dcc",
    "Followers": 298,
    "Net Worth": "$1,647",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 102,
    "Lepton": 0.516
  },
  {
    "Username": "Bimba",
    "Address": "0xce4517646ff8e55ded94b638a31a0dce79ca3b56",
    "Followers": 64,
    "Net Worth": "$1,651",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 61,
    "Lepton": 0.516
  },
  {
    "Username": "Norim",
    "Address": "0xde5a83fdcd07800e5f165486c34edb1525d40e44",
    "Followers": 352,
    "Net Worth": "$1,649",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 108,
    "Lepton": 0.516
  },
  {
    "Username": "Graymachaon",
    "Address": "0x308ccf95d0c38a6fd34ac76d22ac7ca1b7f38153",
    "Followers": 471,
    "Net Worth": "$1,642",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 119,
    "Lepton": 0.515
  },
  {
    "Username": "0xabcd…a85e",
    "Address": "",
    "Followers": 72,
    "Net Worth": "$1,628",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 63,
    "Lepton": 0.514
  },
  {
    "Username": "Pulsechain",
    "Address": "0xa4af8ab8d4b63481a9994e6ac694a4abf0bd42ba",
    "Followers": 250,
    "Net Worth": "$1,615",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 96,
    "Lepton": 0.513
  },
  {
    "Username": "LittleBear",
    "Address": "0xbafc5b52c21a655d6eff4f496543ffcb105b7fab",
    "Followers": 877,
    "Net Worth": "$1,623",
    "Boson Weight": 10,
    "Lepton Weight": 6,
    "Boson": 146,
    "Lepton": 0.514
  },
  {
    "Username": "ElenaT79",
    "Address": "0x5d00c9b5a7d1d7605199ea098e9db33ee023ca33",
    "Followers": 490,
    "Net Worth": "$1,614",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 120,
    "Lepton": 0.513
  },
  {
    "Username": "Azino666",
    "Address": "0x7bfeef335b5f0bfa62fdcd9ce718b469eb7c310f",
    "Followers": 46,
    "Net Worth": "$1,614",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 55,
    "Lepton": 0.513
  },
  {
    "Username": "Antoha",
    "Address": "0xee81f6b34149128c3eeac83fb1e41be6f7c0b308",
    "Followers": 111,
    "Net Worth": "$1,606",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 73,
    "Lepton": 0.512
  },
  {
    "Username": "BankDe",
    "Address": "0x908340298d99a7d563c46f29684862161091b68b",
    "Followers": 430,
    "Net Worth": "$1,588",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 115,
    "Lepton": 0.511
  },
  {
    "Username": "0x4596…cbb8",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$1,590",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 28,
    "Lepton": 0.511
  },
  {
    "Username": "utkuo",
    "Address": "0x5a1a563d50afccba58256a5e8c0c9329adcc8755",
    "Followers": 53,
    "Net Worth": "$1,583",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 57,
    "Lepton": 0.511
  },
  {
    "Username": "0x07e5…b0dd",
    "Address": "",
    "Followers": 19,
    "Net Worth": "$1,583",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 41,
    "Lepton": 0.511
  },
  {
    "Username": "0x6a60…1fba",
    "Address": "",
    "Followers": 36,
    "Net Worth": "$1,574",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 50,
    "Lepton": 0.51
  },
  {
    "Username": "fckmyluck",
    "Address": "0xb6e2831af922dfb15389ec5d2ee7d8d894b9bb0f",
    "Followers": 78,
    "Net Worth": "$1,581",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 65,
    "Lepton": 0.51
  },
  {
    "Username": "Gaia",
    "Address": "0x054c950a46c720125554683227159cfe525d9dd9",
    "Followers": "1,451",
    "Net Worth": "$1,569",
    "Boson Weight": 11,
    "Lepton Weight": 6,
    "Boson": 173,
    "Lepton": 0.509
  },
  {
    "Username": "Maximka105",
    "Address": "0x78a7b3846f6b0407c051aef02e365a76cef586e0",
    "Followers": 201,
    "Net Worth": "$1,562",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 89,
    "Lepton": 0.509
  },
  {
    "Username": "CryptoEdition",
    "Address": "0x34d372684f5a00dba6a025adfbc56c1e209725ed",
    "Followers": 444,
    "Net Worth": "$1,538",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 116,
    "Lepton": 0.507
  },
  {
    "Username": "Jaguarstar",
    "Address": "0x02f86a8783152f5f048afc57b5a2afb5529ee745",
    "Followers": 306,
    "Net Worth": "$1,539",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 103,
    "Lepton": 0.507
  },
  {
    "Username": "0xd38a…7990",
    "Address": "",
    "Followers": 13,
    "Net Worth": "$1,512",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 36,
    "Lepton": 0.505
  },
  {
    "Username": "TheMerge",
    "Address": "0xaf3b18d968b8f1df29bbf6ac1604a2ad814a90f4",
    "Followers": 209,
    "Net Worth": "$1,509",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 90,
    "Lepton": 0.505
  },
  {
    "Username": "yaro2999",
    "Address": "0xb7ee3ec90520b34099e9b015640a97180c339772",
    "Followers": 127,
    "Net Worth": "$1,464",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 77,
    "Lepton": 0.501
  },
  {
    "Username": "Blmaster",
    "Address": "0x776defc2147a9fabd011b8511ebdd8da43ed105e",
    "Followers": "2,228",
    "Net Worth": "$1,454",
    "Boson Weight": 13,
    "Lepton Weight": 6,
    "Boson": 199,
    "Lepton": 0.5
  },
  {
    "Username": "0x8b1b…1426",
    "Address": "",
    "Followers": 12,
    "Net Worth": "$1,443",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 35,
    "Lepton": 0.499
  },
  {
    "Username": "chisno1",
    "Address": "0x7b62d6585f0666f5cfcbea5e09baa99133478a96",
    "Followers": "1,316",
    "Net Worth": "$1,436",
    "Boson Weight": 11,
    "Lepton Weight": 6,
    "Boson": 167,
    "Lepton": 0.498
  },
  {
    "Username": "0x77cf…78de",
    "Address": "",
    "Followers": 18,
    "Net Worth": "$1,427",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 40,
    "Lepton": 0.498
  },
  {
    "Username": "poringbom",
    "Address": "0x8696af03ed690a45cdff7267013484b2c76a38da",
    "Followers": 32,
    "Net Worth": "$1,430",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 48,
    "Lepton": 0.498
  },
  {
    "Username": "0x2e95…61ae",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$1,425",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 38,
    "Lepton": 0.497
  },
  {
    "Username": "0x4a21…e9c2",
    "Address": "",
    "Followers": 10,
    "Net Worth": "$1,429",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 33,
    "Lepton": 0.498
  },
  {
    "Username": "0xa1a4…e032",
    "Address": "",
    "Followers": 13,
    "Net Worth": "$1,424",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 36,
    "Lepton": 0.497
  },
  {
    "Username": "0x44c4…5b86",
    "Address": "",
    "Followers": 16,
    "Net Worth": "$1,421",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 38,
    "Lepton": 0.497
  },
  {
    "Username": "hewayne",
    "Address": "0x0008906ca2e1d42dfb6bbcda7f9b709a0cfa8dfc",
    "Followers": 86,
    "Net Worth": "$1,422",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 67,
    "Lepton": 0.497
  },
  {
    "Username": "0x90c1…3c7b",
    "Address": "",
    "Followers": 14,
    "Net Worth": "$1,421",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 37,
    "Lepton": 0.497
  },
  {
    "Username": "0x30f3…4aef",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$1,416",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 38,
    "Lepton": 0.497
  },
  {
    "Username": "0x4606…c22a",
    "Address": "",
    "Followers": 378,
    "Net Worth": "$1,419",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 110,
    "Lepton": 0.497
  },
  {
    "Username": "oxmaks",
    "Address": "0xbb5e5352fc859b1753db47282be9520ab6aa8b22",
    "Followers": 15,
    "Net Worth": "$1,413",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 38,
    "Lepton": 0.496
  },
  {
    "Username": "0x6eae…cef6",
    "Address": "",
    "Followers": 835,
    "Net Worth": "$1,416",
    "Boson Weight": 9,
    "Lepton Weight": 6,
    "Boson": 144,
    "Lepton": 0.497
  },
  {
    "Username": "0x78af…d8b7",
    "Address": "",
    "Followers": 18,
    "Net Worth": "$1,414",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 40,
    "Lepton": 0.496
  },
  {
    "Username": "Sanaz",
    "Address": "0xfded70da39b43d1350128f8ed7ac82845022cbf2",
    "Followers": "2,048",
    "Net Worth": "$1,411",
    "Boson Weight": 13,
    "Lepton Weight": 6,
    "Boson": 194,
    "Lepton": 0.496
  },
  {
    "Username": "Temka",
    "Address": "0xf30a35fc178a8fd5b01a5745441c56b6a076c44d",
    "Followers": 98,
    "Net Worth": "$1,407",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 70,
    "Lepton": 0.496
  },
  {
    "Username": "0xbab5…b222",
    "Address": "",
    "Followers": 274,
    "Net Worth": "$1,408",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 99,
    "Lepton": 0.496
  },
  {
    "Username": "Crypto_Fan",
    "Address": "0x09643e0435e76b68a8856b4c498244f716ba3468",
    "Followers": 250,
    "Net Worth": "$1,399",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 96,
    "Lepton": 0.495
  },
  {
    "Username": "matveikatimka",
    "Address": "0x00b08309d67eb1d92713ac689dc7a0d26fabd230",
    "Followers": 49,
    "Net Worth": "$1,394",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 56,
    "Lepton": 0.495
  },
  {
    "Username": "0x1b36…b5ec",
    "Address": "",
    "Followers": 105,
    "Net Worth": "$1,389",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 72,
    "Lepton": 0.494
  },
  {
    "Username": "MargoSha",
    "Address": "0xe00a262f10b8914c209e740f659c0cb1200449a6",
    "Followers": 272,
    "Net Worth": "$1,381",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 99,
    "Lepton": 0.493
  },
  {
    "Username": "ZEDRUN",
    "Address": "",
    "Followers": 47,
    "Net Worth": "$1,385",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 55,
    "Lepton": 0.494
  },
  {
    "Username": 6689,
    "Address": "0xa2a3d9a8e4d73262094868f61bfff1ea92be9e52",
    "Followers": "1,561",
    "Net Worth": "$1,375",
    "Boson Weight": 12,
    "Lepton Weight": 6,
    "Boson": 177,
    "Lepton": 0.493
  },
  {
    "Username": "Smile",
    "Address": "0x994153bc17a5ebd5e59db9af7c7db418795e8924",
    "Followers": 172,
    "Net Worth": "$1,374",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 85,
    "Lepton": 0.493
  },
  {
    "Username": "hammaad",
    "Address": "0xc44e84ff8261610211904978f12e763682dafbdc",
    "Followers": 20,
    "Net Worth": "$1,371",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 41,
    "Lepton": 0.493
  },
  {
    "Username": "0x964c…9351",
    "Address": "",
    "Followers": 14,
    "Net Worth": "$1,365",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 37,
    "Lepton": 0.492
  },
  {
    "Username": "Ch1msy7",
    "Address": "0xe5d71ddf0dd88f2d7eecfe0a962f3dfe7023cf5c",
    "Followers": 142,
    "Net Worth": "$1,368",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 80,
    "Lepton": 0.492
  },
  {
    "Username": "Klaus",
    "Address": "0x461ee3ccf4d664993c6d23a9bfe54b7e9aa6b927",
    "Followers": 414,
    "Net Worth": "$1,362",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 114,
    "Lepton": 0.492
  },
  {
    "Username": "Bonnature",
    "Address": "0xd567b8567c72c44f320c9d3f1ba4858a4fa97697",
    "Followers": 138,
    "Net Worth": "$1,362",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 79,
    "Lepton": 0.492
  },
  {
    "Username": "0x12f0…534f",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$1,343",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 26,
    "Lepton": 0.49
  },
  {
    "Username": "0x484d…f266",
    "Address": "",
    "Followers": 33,
    "Net Worth": "$1,354",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 49,
    "Lepton": 0.491
  },
  {
    "Username": "Nasser215",
    "Address": "0x0cc4cfacfbfda1ff5ea003e5a59d17b4c55a5050",
    "Followers": "1,483",
    "Net Worth": "$1,299",
    "Boson Weight": 11,
    "Lepton Weight": 6,
    "Boson": 174,
    "Lepton": 0.486
  },
  {
    "Username": "0x07b8…73cc",
    "Address": "",
    "Followers": 140,
    "Net Worth": "$1,298",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 79,
    "Lepton": 0.486
  },
  {
    "Username": "superpump",
    "Address": "0x0727583792c930a8de97fd13516ef0d544139afd",
    "Followers": 104,
    "Net Worth": "$1,279",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 72,
    "Lepton": 0.484
  },
  {
    "Username": "0xe4e1…b02a",
    "Address": "",
    "Followers": 169,
    "Net Worth": "$1,272",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 84,
    "Lepton": 0.483
  },
  {
    "Username": "0x867f…d048",
    "Address": "",
    "Followers": 102,
    "Net Worth": "$1,260",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 71,
    "Lepton": 0.482
  },
  {
    "Username": 9292,
    "Address": "0x3540f28a9ea3615130bf39fc45502444e3672985",
    "Followers": 290,
    "Net Worth": "$1,255",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 101,
    "Lepton": 0.482
  },
  {
    "Username": "0xe0ad…c71d",
    "Address": "",
    "Followers": "1,394",
    "Net Worth": "$1,255",
    "Boson Weight": 11,
    "Lepton Weight": 6,
    "Boson": 170,
    "Lepton": 0.482
  },
  {
    "Username": "leegt76",
    "Address": "SYBIL",
    "Followers": 341,
    "Net Worth": "$1,243",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 106,
    "Lepton": 0.481
  },
  {
    "Username": "GiGiMan",
    "Address": "0xb55669908e6ec476c18ca83d3753ad1713fd4a03",
    "Followers": 206,
    "Net Worth": "$1,286",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 90,
    "Lepton": 0.485
  },
  {
    "Username": "lamoodcat",
    "Address": "0x3e51c2f221d97ebb6b24f75b7c62fe59b5cdb2b0",
    "Followers": 198,
    "Net Worth": "$1,207",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 89,
    "Lepton": 0.477
  },
  {
    "Username": "Andrrrey",
    "Address": "0xd02e3550604e86c42d806f24af3968a55e37d18b",
    "Followers": "1,156",
    "Net Worth": "$1,210",
    "Boson Weight": 10,
    "Lepton Weight": 6,
    "Boson": 160,
    "Lepton": 0.477
  },
  {
    "Username": "SCORE",
    "Address": "0xc671dd55c60f8f97c74acb102497a3fd873f5f66",
    "Followers": 183,
    "Net Worth": "$1,202",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 87,
    "Lepton": 0.477
  },
  {
    "Username": "SpaceWalker",
    "Address": "0xe20193b98487c9922c8059f2270682c0bac9c561",
    "Followers": 134,
    "Net Worth": "$1,199",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 78,
    "Lepton": 0.476
  },
  {
    "Username": "0x90e1…4e05",
    "Address": "",
    "Followers": 96,
    "Net Worth": "$1,194",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 70,
    "Lepton": 0.476
  },
  {
    "Username": "0x1596…2fb2",
    "Address": "",
    "Followers": 133,
    "Net Worth": "$1,185",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 78,
    "Lepton": 0.475
  },
  {
    "Username": "0x63f2…cd98",
    "Address": "",
    "Followers": 110,
    "Net Worth": "$1,183",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 73,
    "Lepton": 0.475
  },
  {
    "Username": "DimaDegen",
    "Address": "0xcbd56a71a02fa7aa01bf1c94c0aeb2828bebdc0a",
    "Followers": 37,
    "Net Worth": "$1,179",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 51,
    "Lepton": 0.474
  },
  {
    "Username": "0x9969…bd03",
    "Address": "",
    "Followers": "1,783",
    "Net Worth": "$1,180",
    "Boson Weight": 12,
    "Lepton Weight": 6,
    "Boson": 185,
    "Lepton": 0.474
  },
  {
    "Username": "Yuliacoin",
    "Address": "0xd038adc223d93decd29654d8df931a606991ce15",
    "Followers": 272,
    "Net Worth": "$1,137",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 99,
    "Lepton": 0.47
  },
  {
    "Username": "Gitbub",
    "Address": "",
    "Followers": 45,
    "Net Worth": "$1,127",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 54,
    "Lepton": 0.469
  },
  {
    "Username": 95009,
    "Address": "0xfcdd21e1fe6d09f6b6b2125ffd55b2a486767255",
    "Followers": 16,
    "Net Worth": "$1,112",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 38,
    "Lepton": 0.467
  },
  {
    "Username": "0x74a1…34d6",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$1,097",
    "Boson Weight": 1,
    "Lepton Weight": 6,
    "Boson": 19,
    "Lepton": 0.466
  },
  {
    "Username": "NFTerraX",
    "Address": "0x41951a191ec0c833db8c50c713a07b376d083cc1",
    "Followers": 294,
    "Net Worth": "$1,085",
    "Boson Weight": 7,
    "Lepton Weight": 6,
    "Boson": 101,
    "Lepton": 0.465
  },
  {
    "Username": "hodl3rs",
    "Address": "0x0a9dafaed64f012ce737c5b807c653293397b117",
    "Followers": 229,
    "Net Worth": "$1,087",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 93,
    "Lepton": 0.465
  },
  {
    "Username": "0x2a7c…1772",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$1,086",
    "Boson Weight": 1,
    "Lepton Weight": 6,
    "Boson": 22,
    "Lepton": 0.465
  },
  {
    "Username": "0xfa37…dee2",
    "Address": "",
    "Followers": 129,
    "Net Worth": "$1,080",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 77,
    "Lepton": 0.464
  },
  {
    "Username": "Layer1",
    "Address": "0x493ff760788c6c833fcf42def029303ad825922f",
    "Followers": 56,
    "Net Worth": "$1,073",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 58,
    "Lepton": 0.463
  },
  {
    "Username": "0xa2f9…2695",
    "Address": "0xa2f9ab704551a00437196d8313364fdc8d9e2695",
    "Followers": 4,
    "Net Worth": "$1,066",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 24,
    "Lepton": 0.463
  },
  {
    "Username": "0x0389…ae90",
    "Address": "0x0389398734f4d1d377dd5feb5191b96f229eae90",
    "Followers": 11,
    "Net Worth": "$1,034",
    "Boson Weight": 2,
    "Lepton Weight": 6,
    "Boson": 34,
    "Lepton": 0.459
  },
  {
    "Username": "0x8cf3…64da",
    "Address": "0x8cf35d2875810bf64415379905ea2fb117b764da",
    "Followers": 39,
    "Net Worth": "$1,043",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 52,
    "Lepton": 0.46
  },
  {
    "Username": "0x2eb7…2e0d",
    "Address": "0x2eb73cdf547e81789edd0d2670ae8bf045d52e0d",
    "Followers": 462,
    "Net Worth": "$1,023",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 118,
    "Lepton": 0.458
  },
  {
    "Username": "MAYC",
    "Address": "0xe0e82310b21c06ef9c1e92489a9cb542b54ff728",
    "Followers": "2,578",
    "Net Worth": "$1,010",
    "Boson Weight": 14,
    "Lepton Weight": 6,
    "Boson": 209,
    "Lepton": 0.456
  },
  {
    "Username": "yogweb3",
    "Address": "0x1f770a0af0e2dc965c37fcc8a6ca2560472e1d1e",
    "Followers": 149,
    "Net Worth": "$1,005",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 81,
    "Lepton": 0.456
  },
  {
    "Username": "0x8e70…f04e",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$994",
    "Boson Weight": 1,
    "Lepton Weight": 6,
    "Boson": 22,
    "Lepton": 0.455
  },
  {
    "Username": "0x30e3…2278",
    "Address": "0x30e3ea2a26dce07a959f7d57c826c91a20e92278",
    "Followers": 563,
    "Net Worth": "$993",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 126,
    "Lepton": 0.454
  },
  {
    "Username": "0xb77b…666b",
    "Address": "0xb77b0e6cf3886e41e68dbebdb2f59f579dda666b",
    "Followers": 71,
    "Net Worth": "$999",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 63,
    "Lepton": 0.455
  },
  {
    "Username": "0x29e5…7544",
    "Address": "",
    "Followers": 19,
    "Net Worth": "$985",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 41,
    "Lepton": 0.453
  },
  {
    "Username": "0x5131…6a57",
    "Address": "",
    "Followers": 174,
    "Net Worth": "$984",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 85,
    "Lepton": 0.453
  },
  {
    "Username": "0xc142…e444",
    "Address": "",
    "Followers": 61,
    "Net Worth": "$980",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 60,
    "Lepton": 0.453
  },
  {
    "Username": "0x0f04…afe5",
    "Address": "",
    "Followers": 131,
    "Net Worth": "$979",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 77,
    "Lepton": 0.453
  },
  {
    "Username": "buysellshort",
    "Address": "0x73165a7bf621f466c63f6a0c8f08e969772c2a6d",
    "Followers": 425,
    "Net Worth": "$975",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 115,
    "Lepton": 0.452
  },
  {
    "Username": "0x575c…3fef",
    "Address": "",
    "Followers": 69,
    "Net Worth": "$971",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 63,
    "Lepton": 0.452
  },
  {
    "Username": "0x8215…f287",
    "Address": "0x8215526f719ee09346eef72a689f61ef3a28f287",
    "Followers": 70,
    "Net Worth": "$967",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 63,
    "Lepton": 0.451
  },
  {
    "Username": "Neuralink",
    "Address": "0x2769dcf7b37bf6ee5149b4860968b14328e9c831",
    "Followers": 49,
    "Net Worth": "$962",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 56,
    "Lepton": 0.451
  },
  {
    "Username": "ChikaRA",
    "Address": "0x2f144f05c5e07a51758bd1c8fa635ad79b7f4e5c",
    "Followers": 68,
    "Net Worth": "$960",
    "Boson Weight": 4,
    "Lepton Weight": 6,
    "Boson": 62,
    "Lepton": 0.451
  },
  {
    "Username": "token",
    "Address": "0xd4203e6ffc798765dea121d795b2a56684c1c738",
    "Followers": "4,274",
    "Net Worth": "$956",
    "Boson Weight": 16,
    "Lepton Weight": 6,
    "Boson": 247,
    "Lepton": 0.45
  },
  {
    "Username": "Gireesh",
    "Address": "0x5230c81f3a6a841b46bf708f23af153a6433fa14",
    "Followers": 468,
    "Net Worth": "$951",
    "Boson Weight": 8,
    "Lepton Weight": 6,
    "Boson": 118,
    "Lepton": 0.45
  },
  {
    "Username": "RICHASFUCK",
    "Address": "0x780d92fd0af4618051141830cdc447fac1be9f60",
    "Followers": 183,
    "Net Worth": "$941",
    "Boson Weight": 6,
    "Lepton Weight": 6,
    "Boson": 87,
    "Lepton": 0.448
  },
  {
    "Username": "0x0e7e…f5d0",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$942",
    "Boson Weight": 0,
    "Lepton Weight": 6,
    "Boson": 0,
    "Lepton": 0.448
  },
  {
    "Username": "0x703b…1152",
    "Address": "",
    "Followers": 151,
    "Net Worth": "$943",
    "Boson Weight": 5,
    "Lepton Weight": 6,
    "Boson": 81,
    "Lepton": 0.449
  },
  {
    "Username": "0x4339…e21d",
    "Address": "",
    "Followers": 396,
    "Net Worth": "$908",
    "Boson Weight": 7,
    "Lepton Weight": 5,
    "Boson": 112,
    "Lepton": 0.444
  },
  {
    "Username": "choamsan",
    "Address": "0xbf3395f721ced84cd4c630b6c98e18574e551dfb",
    "Followers": 180,
    "Net Worth": "$908",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 86,
    "Lepton": 0.444
  },
  {
    "Username": "0x5ee1…8053",
    "Address": "",
    "Followers": "2,302",
    "Net Worth": "$900",
    "Boson Weight": 13,
    "Lepton Weight": 5,
    "Boson": 201,
    "Lepton": 0.443
  },
  {
    "Username": "Peterrich",
    "Address": "0xec9c38f0a06ef3ab2c549b3eb90c97e69e1e4e9f",
    "Followers": 156,
    "Net Worth": "$900",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 82,
    "Lepton": 0.443
  },
  {
    "Username": "rushpark",
    "Address": "0x54b1cf34a20276af6dbdf5159c35860bad75652e",
    "Followers": 302,
    "Net Worth": "$890",
    "Boson Weight": 7,
    "Lepton Weight": 5,
    "Boson": 102,
    "Lepton": 0.442
  },
  {
    "Username": "0x4fc5…b541",
    "Address": "",
    "Followers": 119,
    "Net Worth": "$891",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 75,
    "Lepton": 0.442
  },
  {
    "Username": 2939,
    "Address": "0x0c58e4e59956083483c8833c5d61fdfe4820b7fb",
    "Followers": 207,
    "Net Worth": "$878",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 90,
    "Lepton": 0.441
  },
  {
    "Username": "0xca07…21fa",
    "Address": "",
    "Followers": 78,
    "Net Worth": "$884",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 65,
    "Lepton": 0.441
  },
  {
    "Username": "0x9b33…7fe1",
    "Address": "",
    "Followers": 39,
    "Net Worth": "$961",
    "Boson Weight": 3,
    "Lepton Weight": 6,
    "Boson": 52,
    "Lepton": 0.451
  },
  {
    "Username": "imrobot",
    "Address": "0x4c0d6b575bc74cc7323e1efcdf60dea84bd263cd",
    "Followers": 121,
    "Net Worth": "$853",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 75,
    "Lepton": 0.437
  },
  {
    "Username": "0xf182…515d",
    "Address": "",
    "Followers": 47,
    "Net Worth": "$857",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 55,
    "Lepton": 0.438
  },
  {
    "Username": "byungwan",
    "Address": "0xa06ae89f2e1146524a26bf00656e212d9c330824",
    "Followers": 137,
    "Net Worth": "$838",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 79,
    "Lepton": 0.436
  },
  {
    "Username": "salenka",
    "Address": "0x5c95482329d44397d88ac5e1ace402cfa56837bd",
    "Followers": 85,
    "Net Worth": "$828",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 67,
    "Lepton": 0.434
  },
  {
    "Username": "VooD",
    "Address": "0x0e6f5abb26bd0b425fc1ec75dab503851052ca9d",
    "Followers": 442,
    "Net Worth": "$815",
    "Boson Weight": 8,
    "Lepton Weight": 5,
    "Boson": 116,
    "Lepton": 0.433
  },
  {
    "Username": 3311,
    "Address": "0xf605e72938ecda6b1f5d2a754acc470e9f2d51ac",
    "Followers": 197,
    "Net Worth": "$810",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 89,
    "Lepton": 0.432
  },
  {
    "Username": "0x5729…a629",
    "Address": "",
    "Followers": 29,
    "Net Worth": "$799",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 47,
    "Lepton": 0.43
  },
  {
    "Username": "0x43d5…d359",
    "Address": "",
    "Followers": 121,
    "Net Worth": "$780",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 75,
    "Lepton": 0.428
  },
  {
    "Username": "four",
    "Address": "0x9891be2e4e8ffa295d9cbd146c6973e8e7b6cc58",
    "Followers": 164,
    "Net Worth": "$775",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 83,
    "Lepton": 0.427
  },
  {
    "Username": "0x953c…8ceb",
    "Address": "",
    "Followers": 233,
    "Net Worth": "$776",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 94,
    "Lepton": 0.427
  },
  {
    "Username": "binance1337",
    "Address": "0x9639ff43ec4ef4f3c61954401b348f359baa792f",
    "Followers": 83,
    "Net Worth": "$753",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 66,
    "Lepton": 0.424
  },
  {
    "Username": "Antonio_2002",
    "Address": "0x46b901ef5ab7ad72c845232006a7089dc92540df",
    "Followers": 175,
    "Net Worth": "$751",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 85,
    "Lepton": 0.424
  },
  {
    "Username": "0xb562…6684",
    "Address": "",
    "Followers": 55,
    "Net Worth": "$748",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 58,
    "Lepton": 0.423
  },
  {
    "Username": "able",
    "Address": "0xf257403742b622ade40bdd38679c978eb371cceb",
    "Followers": 19,
    "Net Worth": "$742",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 41,
    "Lepton": 0.422
  },
  {
    "Username": "0xd7ca…6ba8",
    "Address": "",
    "Followers": 16,
    "Net Worth": "$739",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 38,
    "Lepton": 0.422
  },
  {
    "Username": "0xa236…a21c",
    "Address": "",
    "Followers": 252,
    "Net Worth": "$721",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 96,
    "Lepton": 0.419
  },
  {
    "Username": "DOFAM1N",
    "Address": "0xa6ce5c1688367803b8fd3343320dd33e80028c4c",
    "Followers": 203,
    "Net Worth": "$716",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 90,
    "Lepton": 0.419
  },
  {
    "Username": "0x26af…35fd",
    "Address": "",
    "Followers": "1,005",
    "Net Worth": "$714",
    "Boson Weight": 10,
    "Lepton Weight": 5,
    "Boson": 153,
    "Lepton": 0.418
  },
  {
    "Username": "0x4b84…d17d",
    "Address": "",
    "Followers": 81,
    "Net Worth": "$710",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 66,
    "Lepton": 0.418
  },
  {
    "Username": "0x07a1…ab11",
    "Address": "",
    "Followers": 7,
    "Net Worth": "$713",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 29,
    "Lepton": 0.418
  },
  {
    "Username": "0x631d…6fbb",
    "Address": "",
    "Followers": 219,
    "Net Worth": "$705",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 92,
    "Lepton": 0.417
  },
  {
    "Username": "nobone",
    "Address": "0x524ef914dfbc4b7cf52b2833644c47683118b045",
    "Followers": 269,
    "Net Worth": "$703",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 98,
    "Lepton": 0.417
  },
  {
    "Username": "0x47b3…a12a",
    "Address": "",
    "Followers": 100,
    "Net Worth": "$704",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 71,
    "Lepton": 0.417
  },
  {
    "Username": "0x8e19…c99c",
    "Address": "",
    "Followers": 375,
    "Net Worth": "$703",
    "Boson Weight": 7,
    "Lepton Weight": 5,
    "Boson": 110,
    "Lepton": 0.417
  },
  {
    "Username": 1288,
    "Address": "0xaccf791ded07ab38b66b6e8f241d0625f3f3f0cb",
    "Followers": 121,
    "Net Worth": "$692",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 75,
    "Lepton": 0.415
  },
  {
    "Username": "0x7848…0d21",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$683",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 26,
    "Lepton": 0.414
  },
  {
    "Username": "0xd314…cd59",
    "Address": "",
    "Followers": 135,
    "Net Worth": "$660",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 78,
    "Lepton": 0.41
  },
  {
    "Username": "0x8cff…e32e",
    "Address": "",
    "Followers": 44,
    "Net Worth": "$645",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 54,
    "Lepton": 0.408
  },
  {
    "Username": "lovewins",
    "Address": "0x61ef9960d449d9db33150a0ee2c209397b50426e",
    "Followers": 14,
    "Net Worth": "$644",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 37,
    "Lepton": 0.408
  },
  {
    "Username": "0xdffb…1b13",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$634",
    "Boson Weight": 1,
    "Lepton Weight": 5,
    "Boson": 15,
    "Lepton": 0.406
  },
  {
    "Username": "Samscalet",
    "Address": "0xfcfa4369eaa965ac4e36f1dd9fd2852c6542b0f7",
    "Followers": 14,
    "Net Worth": "$632",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 37,
    "Lepton": 0.406
  },
  {
    "Username": "0x0f97…bc84",
    "Address": "",
    "Followers": 144,
    "Net Worth": "$628",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 80,
    "Lepton": 0.405
  },
  {
    "Username": "hurakacha",
    "Address": "0x6f8d49a54aba02109c0ea27057546e680ee5dec1",
    "Followers": 317,
    "Net Worth": "$626",
    "Boson Weight": 7,
    "Lepton Weight": 5,
    "Boson": 104,
    "Lepton": 0.405
  },
  {
    "Username": "0x3ca5…f357",
    "Address": "",
    "Followers": 35,
    "Net Worth": "$625",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 50,
    "Lepton": 0.405
  },
  {
    "Username": "Milu",
    "Address": "0x1b62fb7a7c1dd807f0e81b1b536d9b165c1a5f66",
    "Followers": 66,
    "Net Worth": "$622",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 62,
    "Lepton": 0.404
  },
  {
    "Username": 2012,
    "Address": "0x3fd01a918c69eca8295052fd1dd744a21ff0ed98",
    "Followers": 38,
    "Net Worth": "$617",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 51,
    "Lepton": 0.403
  },
  {
    "Username": "0xe96d…c9dc",
    "Address": "",
    "Followers": 146,
    "Net Worth": "$598",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 80,
    "Lepton": 0.4
  },
  {
    "Username": "0xff21…9074",
    "Address": "",
    "Followers": 22,
    "Net Worth": "$593",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 43,
    "Lepton": 0.399
  },
  {
    "Username": "0x6a4a…f091",
    "Address": "",
    "Followers": 22,
    "Net Worth": "$592",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 43,
    "Lepton": 0.399
  },
  {
    "Username": "0xab43…bd42",
    "Address": "",
    "Followers": 10,
    "Net Worth": "$590",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 33,
    "Lepton": 0.399
  },
  {
    "Username": "0x3d46…86d2",
    "Address": "",
    "Followers": 61,
    "Net Worth": "$582",
    "Boson Weight": 4,
    "Lepton Weight": 5,
    "Boson": 60,
    "Lepton": 0.398
  },
  {
    "Username": "Explayy",
    "Address": "0xb68e323e1d63286322d08244527d6d6561b21540",
    "Followers": 286,
    "Net Worth": "$581",
    "Boson Weight": 7,
    "Lepton Weight": 5,
    "Boson": 100,
    "Lepton": 0.397
  },
  {
    "Username": "0x2c35…a174",
    "Address": "",
    "Followers": 157,
    "Net Worth": "$575",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 82,
    "Lepton": 0.396
  },
  {
    "Username": "ffocus",
    "Address": "0xb39a0b4a5cd271ee73a28021bf757cfad0616034",
    "Followers": 168,
    "Net Worth": "$573",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 84,
    "Lepton": 0.396
  },
  {
    "Username": "Renardstou",
    "Address": "0x748536810e5f536abfb0bf0b7ba58cff9ed1c8e3",
    "Followers": 242,
    "Net Worth": "$570",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 95,
    "Lepton": 0.396
  },
  {
    "Username": "CryptoTroll",
    "Address": "0x0b957bfd25924baa795affec49e66524164b310c",
    "Followers": 171,
    "Net Worth": "$557",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 85,
    "Lepton": 0.393
  },
  {
    "Username": "0x46cf…79b2",
    "Address": "",
    "Followers": 109,
    "Net Worth": "$557",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 73,
    "Lepton": 0.393
  },
  {
    "Username": "0xdb7b…c141",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$545",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 28,
    "Lepton": 0.391
  },
  {
    "Username": "witek",
    "Address": "0xb0ee517e2da88b6ce35ab8d2a472aa597033a84e",
    "Followers": 139,
    "Net Worth": "$543",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 79,
    "Lepton": 0.391
  },
  {
    "Username": "0xb58f…863c",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$534",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 28,
    "Lepton": 0.389
  },
  {
    "Username": "stefan",
    "Address": "0xf1e59a9e96afaf1ae0e3e61a5560c977368b5e29",
    "Followers": 136,
    "Net Worth": "$558",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 78,
    "Lepton": 0.393
  },
  {
    "Username": "0xa7c6…f99c",
    "Address": "",
    "Followers": 136,
    "Net Worth": "$537",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 78,
    "Lepton": 0.39
  },
  {
    "Username": "ArbitrumOne",
    "Address": "0x8761b83adec94ba4bd8b74919fccbc986834e73f",
    "Followers": 42,
    "Net Worth": "$525",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 53,
    "Lepton": 0.387
  },
  {
    "Username": "0x7084…f4ca",
    "Address": "",
    "Followers": 11,
    "Net Worth": "$524",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 34,
    "Lepton": 0.387
  },
  {
    "Username": "0x48a2…a50b",
    "Address": "",
    "Followers": 389,
    "Net Worth": "$514",
    "Boson Weight": 7,
    "Lepton Weight": 5,
    "Boson": 111,
    "Lepton": 0.385
  },
  {
    "Username": "0xaf70…92a5",
    "Address": "",
    "Followers": 34,
    "Net Worth": "$496",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 49,
    "Lepton": 0.382
  },
  {
    "Username": "Cirnati",
    "Address": "0x6dd3c3a01c0c28fe2a19fcb9c16609c048a6bd94",
    "Followers": 24,
    "Net Worth": "$494",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 44,
    "Lepton": 0.382
  },
  {
    "Username": "0xd702…4f7d",
    "Address": "",
    "Followers": 505,
    "Net Worth": "$490",
    "Boson Weight": 8,
    "Lepton Weight": 5,
    "Boson": 121,
    "Lepton": 0.381
  },
  {
    "Username": "0xa73c…879a",
    "Address": "",
    "Followers": "1,176",
    "Net Worth": "$483",
    "Boson Weight": 11,
    "Lepton Weight": 5,
    "Boson": 161,
    "Lepton": 0.379
  },
  {
    "Username": "0x16d2…6c1f",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$460",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 38,
    "Lepton": 0.375
  },
  {
    "Username": "0x333c…0e74",
    "Address": "",
    "Followers": 10,
    "Net Worth": "$454",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 33,
    "Lepton": 0.374
  },
  {
    "Username": "0x97f1…acc5",
    "Address": "",
    "Followers": 30,
    "Net Worth": "$455",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 47,
    "Lepton": 0.374
  },
  {
    "Username": "0x9190…51c2",
    "Address": "",
    "Followers": 112,
    "Net Worth": "$454",
    "Boson Weight": 5,
    "Lepton Weight": 5,
    "Boson": 73,
    "Lepton": 0.374
  },
  {
    "Username": "umea",
    "Address": "0x854a6b84ad48645eac476d0d8197f854c06dd79d",
    "Followers": 389,
    "Net Worth": "$443",
    "Boson Weight": 7,
    "Lepton Weight": 5,
    "Boson": 111,
    "Lepton": 0.371
  },
  {
    "Username": "Vacar",
    "Address": "0x4ab282bc54d0feb1713d7ba885b574dd377134b1",
    "Followers": 15,
    "Net Worth": "$444",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 38,
    "Lepton": 0.372
  },
  {
    "Username": "0x6b21…64d4",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$435",
    "Boson Weight": 1,
    "Lepton Weight": 5,
    "Boson": 19,
    "Lepton": 0.37
  },
  {
    "Username": "Zilant25",
    "Address": "0x4d999f16ec6fd46a84e3c2cd4a9a64dd314ae829",
    "Followers": 224,
    "Net Worth": "$432",
    "Boson Weight": 6,
    "Lepton Weight": 5,
    "Boson": 93,
    "Lepton": 0.369
  },
  {
    "Username": "0x3ffa…a6e8",
    "Address": "",
    "Followers": 26,
    "Net Worth": "$431",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 45,
    "Lepton": 0.369
  },
  {
    "Username": "italy",
    "Address": "0xa89247b5e264fa73b9cdc3f624c0494b64534c59",
    "Followers": 13,
    "Net Worth": "$422",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 36,
    "Lepton": 0.367
  },
  {
    "Username": "0x5acd…ff9a",
    "Address": "",
    "Followers": 30,
    "Net Worth": "$418",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 47,
    "Lepton": 0.366
  },
  {
    "Username": "0xf10d…7319",
    "Address": "",
    "Followers": 40,
    "Net Worth": "$416",
    "Boson Weight": 3,
    "Lepton Weight": 5,
    "Boson": 52,
    "Lepton": 0.366
  },
  {
    "Username": "0x183a…e7d1",
    "Address": "",
    "Followers": 10,
    "Net Worth": "$413",
    "Boson Weight": 2,
    "Lepton Weight": 5,
    "Boson": 33,
    "Lepton": 0.365
  },
  {
    "Username": "0x67c2…f197",
    "Address": "",
    "Followers": 66,
    "Net Worth": "$390",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 62,
    "Lepton": 0.36
  },
  {
    "Username": "0xbbca…7ffe",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$386",
    "Boson Weight": 0,
    "Lepton Weight": 4,
    "Boson": 0,
    "Lepton": 0.359
  },
  {
    "Username": "0x6213…c84b",
    "Address": "",
    "Followers": "1,732",
    "Net Worth": "$387",
    "Boson Weight": 12,
    "Lepton Weight": 4,
    "Boson": 183,
    "Lepton": 0.359
  },
  {
    "Username": 12111,
    "Address": "0x1b8fdae43ccb4fd9cfcef597fdcdf382ae598914",
    "Followers": 32,
    "Net Worth": "$389",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 48,
    "Lepton": 0.359
  },
  {
    "Username": "0x8f22…7358",
    "Address": "",
    "Followers": 18,
    "Net Worth": "$389",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 40,
    "Lepton": 0.359
  },
  {
    "Username": "0x826d…bc86",
    "Address": "",
    "Followers": 178,
    "Net Worth": "$390",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 86,
    "Lepton": 0.36
  },
  {
    "Username": "0xbf33…2f44",
    "Address": "",
    "Followers": 20,
    "Net Worth": "$384",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 41,
    "Lepton": 0.358
  },
  {
    "Username": "shib",
    "Address": "0x6f1eb522696b1c7e842bf32cb0bf0d4084b9cea2",
    "Followers": "2,879",
    "Net Worth": "$382",
    "Boson Weight": 14,
    "Lepton Weight": 4,
    "Boson": 217,
    "Lepton": 0.358
  },
  {
    "Username": "0x97a2…6648",
    "Address": "",
    "Followers": 35,
    "Net Worth": "$370",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 50,
    "Lepton": 0.355
  },
  {
    "Username": "0x3db3…2338",
    "Address": "",
    "Followers": 16,
    "Net Worth": "$361",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 38,
    "Lepton": 0.353
  },
  {
    "Username": "0xe7b8…96d0",
    "Address": "",
    "Followers": 608,
    "Net Worth": "$359",
    "Boson Weight": 8,
    "Lepton Weight": 4,
    "Boson": 129,
    "Lepton": 0.352
  },
  {
    "Username": "0x1207…9da6",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$359",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 26,
    "Lepton": 0.352
  },
  {
    "Username": "0x8fb5…b985",
    "Address": "",
    "Followers": 9,
    "Net Worth": "$356",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 32,
    "Lepton": 0.352
  },
  {
    "Username": "Asuka",
    "Address": "0xa3f089891e8a34fd56226688cb577e257d12b7f3",
    "Followers": 80,
    "Net Worth": "$357",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 66,
    "Lepton": 0.352
  },
  {
    "Username": "0x9b04…26bc",
    "Address": "",
    "Followers": 24,
    "Net Worth": "$354",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 44,
    "Lepton": 0.351
  },
  {
    "Username": "0x8ba0…9d95",
    "Address": "",
    "Followers": 13,
    "Net Worth": "$353",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 36,
    "Lepton": 0.351
  },
  {
    "Username": "CryptoSnail",
    "Address": "0x17ca1e8e39c02f662508d1d9307efaf1e9d8ddd6",
    "Followers": 149,
    "Net Worth": "$341",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 81,
    "Lepton": 0.348
  },
  {
    "Username": "zzzz",
    "Address": "0x218ca463f3a7439fbe3d152a0aaab861507fc235",
    "Followers": 163,
    "Net Worth": "$330",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 83,
    "Lepton": 0.345
  },
  {
    "Username": "0x4f0d…b91c",
    "Address": "",
    "Followers": 163,
    "Net Worth": "$337",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 83,
    "Lepton": 0.347
  },
  {
    "Username": "yoshiwaitup",
    "Address": "0x10d16a49abe3fd65469d9022aeb8a9317d4b0ffd",
    "Followers": 616,
    "Net Worth": "$328",
    "Boson Weight": 9,
    "Lepton Weight": 4,
    "Boson": 130,
    "Lepton": 0.344
  },
  {
    "Username": "4eJlc",
    "Address": "0x700d8a76b37f672a06ab89fe1ec95acfba799f1c",
    "Followers": 271,
    "Net Worth": "$327",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 99,
    "Lepton": 0.344
  },
  {
    "Username": "LittleBoo",
    "Address": "0x01580bc94dc6ddd521d0dae98d906fe91b360539",
    "Followers": 128,
    "Net Worth": "$379",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 77,
    "Lepton": 0.357
  },
  {
    "Username": "0x713c…47d1",
    "Address": "",
    "Followers": 12,
    "Net Worth": "$316",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 35,
    "Lepton": 0.341
  },
  {
    "Username": "hoda19ad",
    "Address": "0x0b00ab0a5e1b1e7a88e246a27b8a60e02f063bf9",
    "Followers": "1,911",
    "Net Worth": "$310",
    "Boson Weight": 12,
    "Lepton Weight": 4,
    "Boson": 189,
    "Lepton": 0.34
  },
  {
    "Username": "Daviddu",
    "Address": "0x7033ce11621aac2f4ea41bc752c9c3212cd7565c",
    "Followers": 163,
    "Net Worth": "$310",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 83,
    "Lepton": 0.34
  },
  {
    "Username": "0x6df4…4e00",
    "Address": "",
    "Followers": 183,
    "Net Worth": "$312",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 87,
    "Lepton": 0.34
  },
  {
    "Username": "0x8712…7b22",
    "Address": "",
    "Followers": 22,
    "Net Worth": "$301",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 43,
    "Lepton": 0.337
  },
  {
    "Username": "0x28a9…0322",
    "Address": "",
    "Followers": 124,
    "Net Worth": "$302",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 76,
    "Lepton": 0.337
  },
  {
    "Username": "blinddev",
    "Address": "0xaeac266a4533cb0b4255ea2922f997353a18b2e8",
    "Followers": 119,
    "Net Worth": "$300",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 75,
    "Lepton": 0.337
  },
  {
    "Username": "0x1ea8…0e22",
    "Address": "",
    "Followers": 50,
    "Net Worth": "$300",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 56,
    "Lepton": 0.337
  },
  {
    "Username": "0x34ba…3b32",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$295",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 19,
    "Lepton": 0.335
  },
  {
    "Username": "SQUADRA",
    "Address": "0x222370fbb2a27f0fa6783c552460ca0b4f9fc1b0",
    "Followers": 600,
    "Net Worth": "$293",
    "Boson Weight": 8,
    "Lepton Weight": 4,
    "Boson": 129,
    "Lepton": 0.335
  },
  {
    "Username": "EllizTrade",
    "Address": "0x397544167de84eb4065b082f32d82fb800936f75",
    "Followers": 275,
    "Net Worth": "$294",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 99,
    "Lepton": 0.335
  },
  {
    "Username": "prigozhin",
    "Address": "0x382fc845905a5fa6159434314162005387e03c56",
    "Followers": 63,
    "Net Worth": "$289",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 61,
    "Lepton": 0.334
  },
  {
    "Username": "softtuna",
    "Address": "0xb9a3d9b068a44c964a609a6655b58bfd0494e281",
    "Followers": 195,
    "Net Worth": "$284",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 88,
    "Lepton": 0.332
  },
  {
    "Username": "0x5123…8c01",
    "Address": "",
    "Followers": "2,919",
    "Net Worth": "$278",
    "Boson Weight": 14,
    "Lepton Weight": 4,
    "Boson": 218,
    "Lepton": 0.331
  },
  {
    "Username": "solodka",
    "Address": "0xa2f76973b579764fb7f999061c1bb437f6518871",
    "Followers": 95,
    "Net Worth": "$279",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 70,
    "Lepton": 0.331
  },
  {
    "Username": "0x2f20…6c98",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$277",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 28,
    "Lepton": 0.33
  },
  {
    "Username": "0x043c…1aed",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$275",
    "Boson Weight": 0,
    "Lepton Weight": 4,
    "Boson": 0,
    "Lepton": 0.33
  },
  {
    "Username": "0x926a…d30a",
    "Address": "",
    "Followers": 168,
    "Net Worth": "$278",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 84,
    "Lepton": 0.331
  },
  {
    "Username": "0xca2d…dd5b",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$278",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 19,
    "Lepton": 0.331
  },
  {
    "Username": "0xac0d…8cdd",
    "Address": "",
    "Followers": 829,
    "Net Worth": "$275",
    "Boson Weight": 9,
    "Lepton Weight": 4,
    "Boson": 143,
    "Lepton": 0.33
  },
  {
    "Username": "bank",
    "Address": "0x59ba287dba3aa684ce35eda3589886fbeb5f53b0",
    "Followers": "2,972",
    "Net Worth": "$279",
    "Boson Weight": 14,
    "Lepton Weight": 4,
    "Boson": 219,
    "Lepton": 0.331
  },
  {
    "Username": "0xae5f…998e",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$275",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 15,
    "Lepton": 0.33
  },
  {
    "Username": "wenw",
    "Address": "0x35c0c0ac4140d11fe2c72795a46df8f60afb2814",
    "Followers": "3,114",
    "Net Worth": "$274",
    "Boson Weight": 15,
    "Lepton Weight": 4,
    "Boson": 223,
    "Lepton": 0.329
  },
  {
    "Username": "0x9da6…2dcd",
    "Address": "",
    "Followers": 298,
    "Net Worth": "$273",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 102,
    "Lepton": 0.329
  },
  {
    "Username": "IKIGAI",
    "Address": "0xa7b1c37b41b9575ba632e02cae87eea449b8f7f4",
    "Followers": 76,
    "Net Worth": "$270",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 65,
    "Lepton": 0.328
  },
  {
    "Username": "nade061",
    "Address": "0x4940fb2b88dfb4d6a99a143b97252c372958f58e",
    "Followers": "1,414",
    "Net Worth": "$268",
    "Boson Weight": 11,
    "Lepton Weight": 4,
    "Boson": 171,
    "Lepton": 0.328
  },
  {
    "Username": "0x5c39…e368",
    "Address": "",
    "Followers": 9,
    "Net Worth": "$265",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 32,
    "Lepton": 0.327
  },
  {
    "Username": "soulx",
    "Address": "0x105e5a87f2bd4733de4e88698ed0869d440689dc",
    "Followers": 402,
    "Net Worth": "$268",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 113,
    "Lepton": 0.328
  },
  {
    "Username": "0xfc5e…d54a",
    "Address": "",
    "Followers": 961,
    "Net Worth": "$265",
    "Boson Weight": 10,
    "Lepton Weight": 4,
    "Boson": 150,
    "Lepton": 0.327
  },
  {
    "Username": "ushow",
    "Address": "0x46149618e61df23ef62440e00b012cafab7e0791",
    "Followers": "2,316",
    "Net Worth": "$262",
    "Boson Weight": 13,
    "Lepton Weight": 4,
    "Boson": 202,
    "Lepton": 0.326
  },
  {
    "Username": "0x8ddb…f107",
    "Address": "",
    "Followers": "2,901",
    "Net Worth": "$261",
    "Boson Weight": 14,
    "Lepton Weight": 4,
    "Boson": 217,
    "Lepton": 0.325
  },
  {
    "Username": "0x308e…10d9",
    "Address": "",
    "Followers": 292,
    "Net Worth": "$257",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 101,
    "Lepton": 0.324
  },
  {
    "Username": "0x04a0…4e7c",
    "Address": "",
    "Followers": 86,
    "Net Worth": "$258",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 67,
    "Lepton": 0.324
  },
  {
    "Username": "0xd9cb…f566",
    "Address": "",
    "Followers": 33,
    "Net Worth": "$255",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 49,
    "Lepton": 0.323
  },
  {
    "Username": "Forpost",
    "Address": "0x7856d773e6c051e2b9208976f63f5c91a39add65",
    "Followers": 242,
    "Net Worth": "$255",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 95,
    "Lepton": 0.323
  },
  {
    "Username": "Emperorcoin",
    "Address": "0xa85d235522f0fe2bf6c19804cdba29b06425c760",
    "Followers": 356,
    "Net Worth": "$247",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 108,
    "Lepton": 0.321
  },
  {
    "Username": "0x814b…5218",
    "Address": "",
    "Followers": 141,
    "Net Worth": "$242",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 79,
    "Lepton": 0.319
  },
  {
    "Username": "0x98e9…64e2",
    "Address": "",
    "Followers": 9,
    "Net Worth": "$243",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 32,
    "Lepton": 0.32
  },
  {
    "Username": "0x8781…1746",
    "Address": "",
    "Followers": 555,
    "Net Worth": "$236",
    "Boson Weight": 8,
    "Lepton Weight": 4,
    "Boson": 125,
    "Lepton": 0.317
  },
  {
    "Username": "0x7a29…485a",
    "Address": "",
    "Followers": 47,
    "Net Worth": "$235",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 55,
    "Lepton": 0.317
  },
  {
    "Username": "0x61fa…7542",
    "Address": "",
    "Followers": 49,
    "Net Worth": "$233",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 56,
    "Lepton": 0.316
  },
  {
    "Username": "0x4953…c6c9",
    "Address": "",
    "Followers": 68,
    "Net Worth": "$234",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 62,
    "Lepton": 0.317
  },
  {
    "Username": "0xaffb…27f9",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$357",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 15,
    "Lepton": 0.352
  },
  {
    "Username": "0x11c7…b3b3",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$231",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 22,
    "Lepton": 0.316
  },
  {
    "Username": "0x8c67…1226",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$224",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 22,
    "Lepton": 0.313
  },
  {
    "Username": "0x8ad3…f1f9",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$224",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 28,
    "Lepton": 0.313
  },
  {
    "Username": "0x7e3c…bd25",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$223",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 26,
    "Lepton": 0.313
  },
  {
    "Username": "0x9a16…be82",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$223",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 19,
    "Lepton": 0.313
  },
  {
    "Username": "0x2425…0a20",
    "Address": "",
    "Followers": 254,
    "Net Worth": "$222",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 97,
    "Lepton": 0.312
  },
  {
    "Username": "0xdfc1…f518",
    "Address": "",
    "Followers": 69,
    "Net Worth": "$221",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 63,
    "Lepton": 0.312
  },
  {
    "Username": "0xe6b7…9990",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$221",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 19,
    "Lepton": 0.312
  },
  {
    "Username": "0xca6c…6eae",
    "Address": "",
    "Followers": 223,
    "Net Worth": "$217",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 92,
    "Lepton": 0.311
  },
  {
    "Username": "kimonoRed",
    "Address": "0xcd14273d53dbd64fe83c823d2b1dc5e12a264003",
    "Followers": 384,
    "Net Worth": "$216",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 111,
    "Lepton": 0.31
  },
  {
    "Username": "0xd4ba…9659",
    "Address": "",
    "Followers": 142,
    "Net Worth": "$215",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 80,
    "Lepton": 0.31
  },
  {
    "Username": "0x5819…2e37",
    "Address": "",
    "Followers": 75,
    "Net Worth": "$212",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 64,
    "Lepton": 0.309
  },
  {
    "Username": "kazemiro",
    "Address": "0x7820d6f8c40dc5ac0381d72bcde0fbc9e860396a",
    "Followers": 275,
    "Net Worth": "$216",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 99,
    "Lepton": 0.31
  },
  {
    "Username": "Amazonindia",
    "Address": "0x7de5ffa742adc512dc75aaaddfb55336edf7afd4",
    "Followers": 85,
    "Net Worth": "$210",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 67,
    "Lepton": 0.308
  },
  {
    "Username": "illusion",
    "Address": "0xfddc3034e0fa83b78704417430617e3a7b6cbb10",
    "Followers": 291,
    "Net Worth": "$206",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 101,
    "Lepton": 0.307
  },
  {
    "Username": "0xa8b5…906f",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$211",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 28,
    "Lepton": 0.309
  },
  {
    "Username": "he0928",
    "Address": "0x34e7b42efa18ab64e9f479205b13dfd91ab87870",
    "Followers": 258,
    "Net Worth": "$205",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 97,
    "Lepton": 0.306
  },
  {
    "Username": "wattoth",
    "Address": "0x4aac28b42d754b7418b393263933cabfd645b14e",
    "Followers": 116,
    "Net Worth": "$208",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 74,
    "Lepton": 0.307
  },
  {
    "Username": "alwaysinlove",
    "Address": "0x6cdc6f4b7feb9e3097b06fa9526bf56fa8185bf4",
    "Followers": 70,
    "Net Worth": "$205",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 63,
    "Lepton": 0.306
  },
  {
    "Username": "0xe537…da83",
    "Address": "",
    "Followers": 268,
    "Net Worth": "$204",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 98,
    "Lepton": 0.306
  },
  {
    "Username": "0x5c0b…fcea",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$202",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 28,
    "Lepton": 0.305
  },
  {
    "Username": "dgam777",
    "Address": "0x24a000804704b9557466382a806b6baa31cf574c",
    "Followers": 600,
    "Net Worth": "$202",
    "Boson Weight": 8,
    "Lepton Weight": 4,
    "Boson": 129,
    "Lepton": 0.305
  },
  {
    "Username": "0x51fb…7e0b",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$204",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 15,
    "Lepton": 0.306
  },
  {
    "Username": "0xdc82…a1af",
    "Address": "",
    "Followers": 177,
    "Net Worth": "$202",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 86,
    "Lepton": 0.305
  },
  {
    "Username": "Melos",
    "Address": "0x9bdfe468ed0d21ce9290e4b84575208e36ac0609",
    "Followers": 393,
    "Net Worth": "$199",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 112,
    "Lepton": 0.304
  },
  {
    "Username": "sokol",
    "Address": "0xbb65e0454a7f82a4b936390548ada701f491466d",
    "Followers": 30,
    "Net Worth": "$201",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 47,
    "Lepton": 0.305
  },
  {
    "Username": "maya",
    "Address": "0x72ff3007b05268c5ed55d3fc3fa4ecd7b599627e",
    "Followers": 82,
    "Net Worth": "$199",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 66,
    "Lepton": 0.304
  },
  {
    "Username": "0x0223…84b3",
    "Address": "",
    "Followers": 4,
    "Net Worth": "$195",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 24,
    "Lepton": 0.302
  },
  {
    "Username": "oksyl",
    "Address": "0x786f63b089616f9175e0ebe58065dbeee7d3ae2f",
    "Followers": 681,
    "Net Worth": "$197",
    "Boson Weight": 9,
    "Lepton Weight": 4,
    "Boson": 134,
    "Lepton": 0.303
  },
  {
    "Username": "0x4115…60d6",
    "Address": "",
    "Followers": 581,
    "Net Worth": "$189",
    "Boson Weight": 8,
    "Lepton Weight": 4,
    "Boson": 127,
    "Lepton": 0.3
  },
  {
    "Username": "0x8665…e034",
    "Address": "",
    "Followers": 142,
    "Net Worth": "$193",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 80,
    "Lepton": 0.302
  },
  {
    "Username": "0x0c59…6444",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$191",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 22,
    "Lepton": 0.301
  },
  {
    "Username": "0xf871…1c62",
    "Address": "",
    "Followers": 53,
    "Net Worth": "$192",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 57,
    "Lepton": 0.301
  },
  {
    "Username": "0x744c…072e",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$188",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 15,
    "Lepton": 0.3
  },
  {
    "Username": "0xd598…d751",
    "Address": "",
    "Followers": 35,
    "Net Worth": "$182",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 50,
    "Lepton": 0.297
  },
  {
    "Username": "0x4e1e…0000",
    "Address": "",
    "Followers": 49,
    "Net Worth": "$186",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 56,
    "Lepton": 0.299
  },
  {
    "Username": "0xea69…f79b",
    "Address": "",
    "Followers": 198,
    "Net Worth": "$180",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 89,
    "Lepton": 0.296
  },
  {
    "Username": "0x8fde…dc25",
    "Address": "",
    "Followers": 28,
    "Net Worth": "$178",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 46,
    "Lepton": 0.296
  },
  {
    "Username": "0xaeec…f023",
    "Address": "",
    "Followers": 26,
    "Net Worth": "$177",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 45,
    "Lepton": 0.295
  },
  {
    "Username": "Lucky_Crypto",
    "Address": "0x26e2c0ba5ff83a0fdcbd90823689bb3a1a332604",
    "Followers": 178,
    "Net Worth": "$180",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 86,
    "Lepton": 0.296
  },
  {
    "Username": "0x25a8…27e2",
    "Address": "",
    "Followers": 35,
    "Net Worth": "$176",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 50,
    "Lepton": 0.295
  },
  {
    "Username": "0x95fc…c335",
    "Address": "",
    "Followers": 17,
    "Net Worth": "$175",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 39,
    "Lepton": 0.294
  },
  {
    "Username": "0xdab4…2daa",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$174",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 19,
    "Lepton": 0.294
  },
  {
    "Username": "0x43ce…f909",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$172",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 15,
    "Lepton": 0.293
  },
  {
    "Username": "0x878e…6955",
    "Address": "",
    "Followers": 188,
    "Net Worth": "$174",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 87,
    "Lepton": 0.294
  },
  {
    "Username": "Vitaha",
    "Address": "0x1b8c7b16317d658fc2193a582216b4af0c65cc3f",
    "Followers": 107,
    "Net Worth": "$170",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 72,
    "Lepton": 0.292
  },
  {
    "Username": "0x4e57…74d3",
    "Address": "",
    "Followers": 364,
    "Net Worth": "$171",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 109,
    "Lepton": 0.293
  },
  {
    "Username": "0x3747…4294",
    "Address": "",
    "Followers": 7,
    "Net Worth": "$169",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 29,
    "Lepton": 0.292
  },
  {
    "Username": "0x5d6c…bcca",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$170",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 19,
    "Lepton": 0.292
  },
  {
    "Username": "0x0d28…36c7",
    "Address": "",
    "Followers": 265,
    "Net Worth": "$167",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 98,
    "Lepton": 0.291
  },
  {
    "Username": "0x7d83…b0fd",
    "Address": "",
    "Followers": 11,
    "Net Worth": "$167",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 34,
    "Lepton": 0.291
  },
  {
    "Username": "ChaoGe",
    "Address": "0xec990bf507ce5e544c9a1245823f95b0911a8948",
    "Followers": 205,
    "Net Worth": "$164",
    "Boson Weight": 6,
    "Lepton Weight": 4,
    "Boson": 90,
    "Lepton": 0.29
  },
  {
    "Username": "STIGGA",
    "Address": "0x19ee463a37671e45f929ce1d0f2596fd458351df",
    "Followers": 105,
    "Net Worth": "$162",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 72,
    "Lepton": 0.289
  },
  {
    "Username": "0x1149…bd40",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$165",
    "Boson Weight": 0,
    "Lepton Weight": 4,
    "Boson": 0,
    "Lepton": 0.29
  },
  {
    "Username": "0x8370…e2e8",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$163",
    "Boson Weight": 1,
    "Lepton Weight": 4,
    "Boson": 19,
    "Lepton": 0.289
  },
  {
    "Username": "Dexter1337",
    "Address": "0x83d3f9a8175649ab3f0e3474b249b5b1a38ff2ac",
    "Followers": 124,
    "Net Worth": "$160",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 76,
    "Lepton": 0.288
  },
  {
    "Username": "0x3881…8479",
    "Address": "",
    "Followers": 20,
    "Net Worth": "$164",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 41,
    "Lepton": 0.29
  },
  {
    "Username": "0x9b8d…9f20",
    "Address": "",
    "Followers": 539,
    "Net Worth": "$158",
    "Boson Weight": 8,
    "Lepton Weight": 4,
    "Boson": 124,
    "Lepton": 0.287
  },
  {
    "Username": "kurdex",
    "Address": "0x8a66bee3ed3c0842e04576002e3b9eae90d8592b",
    "Followers": "1,308",
    "Net Worth": "$160",
    "Boson Weight": 11,
    "Lepton Weight": 4,
    "Boson": 167,
    "Lepton": 0.288
  },
  {
    "Username": "0x4ed2…234a",
    "Address": "",
    "Followers": 89,
    "Net Worth": "$158",
    "Boson Weight": 4,
    "Lepton Weight": 4,
    "Boson": 68,
    "Lepton": 0.287
  },
  {
    "Username": "DotOracle",
    "Address": "0xf8bde71eb161bd83da88bd3a1003eef9ba0c7485",
    "Followers": 770,
    "Net Worth": "$159",
    "Boson Weight": 9,
    "Lepton Weight": 4,
    "Boson": 140,
    "Lepton": 0.287
  },
  {
    "Username": "0x940a…a3ce",
    "Address": "",
    "Followers": 11,
    "Net Worth": "$155",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 34,
    "Lepton": 0.286
  },
  {
    "Username": "x2y2",
    "Address": "0x2cf514ed2266c7eb9bc71736aa822a8825b4db5d",
    "Followers": 336,
    "Net Worth": "$154",
    "Boson Weight": 7,
    "Lepton Weight": 4,
    "Boson": 106,
    "Lepton": 0.285
  },
  {
    "Username": "0xf1ff…58fe",
    "Address": "",
    "Followers": 7,
    "Net Worth": "$153",
    "Boson Weight": 2,
    "Lepton Weight": 4,
    "Boson": 29,
    "Lepton": 0.285
  },
  {
    "Username": "RobinGos",
    "Address": "0x009ba007a7ef1523aa58e8ec4f93a20d246fb0e6",
    "Followers": 99,
    "Net Worth": "$210",
    "Boson Weight": 5,
    "Lepton Weight": 4,
    "Boson": 71,
    "Lepton": 0.308
  },
  {
    "Username": "0x90d4…2f50",
    "Address": "",
    "Followers": 36,
    "Net Worth": "$150",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 50,
    "Lepton": 0.283
  },
  {
    "Username": "maiquan",
    "Address": "0xc3bc613626bd68cad057bcd85914d40de80e509e",
    "Followers": 36,
    "Net Worth": "$151",
    "Boson Weight": 3,
    "Lepton Weight": 4,
    "Boson": 50,
    "Lepton": 0.284
  },
  {
    "Username": "0xdcda…4cd8",
    "Address": "",
    "Followers": "2,532",
    "Net Worth": "$149",
    "Boson Weight": 14,
    "Lepton Weight": 3,
    "Boson": 208,
    "Lepton": 0.283
  },
  {
    "Username": "0x7ff5…9720",
    "Address": "",
    "Followers": 503,
    "Net Worth": "$145",
    "Boson Weight": 8,
    "Lepton Weight": 3,
    "Boson": 121,
    "Lepton": 0.281
  },
  {
    "Username": "0x7617…2cd5",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$148",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.282
  },
  {
    "Username": "Helena",
    "Address": "0x9d316c8422a3e07c9c7a29eb6776ecf6cfce851d",
    "Followers": 216,
    "Net Worth": "$144",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 91,
    "Lepton": 0.28
  },
  {
    "Username": "0x096a…726e",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$142",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 22,
    "Lepton": 0.279
  },
  {
    "Username": "0xd843…2435",
    "Address": "",
    "Followers": 324,
    "Net Worth": "$145",
    "Boson Weight": 7,
    "Lepton Weight": 3,
    "Boson": 105,
    "Lepton": 0.281
  },
  {
    "Username": "0x4d19…9ec8",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$141",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 19,
    "Lepton": 0.279
  },
  {
    "Username": "0xc7d8…e8c3",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$145",
    "Boson Weight": 0,
    "Lepton Weight": 3,
    "Boson": 0,
    "Lepton": 0.281
  },
  {
    "Username": "0x7de7…e555",
    "Address": "",
    "Followers": 16,
    "Net Worth": "$143",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 38,
    "Lepton": 0.28
  },
  {
    "Username": "0xffea…3c5e",
    "Address": "",
    "Followers": "1,119",
    "Net Worth": "$140",
    "Boson Weight": 10,
    "Lepton Weight": 3,
    "Boson": 158,
    "Lepton": 0.278
  },
  {
    "Username": "0x5662…76c4",
    "Address": "",
    "Followers": 14,
    "Net Worth": "$139",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 37,
    "Lepton": 0.278
  },
  {
    "Username": "0xffc7…cbb0",
    "Address": "",
    "Followers": 486,
    "Net Worth": "$133",
    "Boson Weight": 8,
    "Lepton Weight": 3,
    "Boson": 120,
    "Lepton": 0.275
  },
  {
    "Username": "0x960b…aee6",
    "Address": "",
    "Followers": 4,
    "Net Worth": "$129",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 24,
    "Lepton": 0.273
  },
  {
    "Username": "0xd5bb…fae3",
    "Address": "",
    "Followers": 95,
    "Net Worth": "$129",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 70,
    "Lepton": 0.273
  },
  {
    "Username": "Individ",
    "Address": "0x151e31efe1d0369c567cbc717d2d413ff8387672",
    "Followers": 138,
    "Net Worth": "$129",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 79,
    "Lepton": 0.273
  },
  {
    "Username": "mozgoprav",
    "Address": "0x6d83c0ba6f39ea132e42afe8b166f3d34638d73b",
    "Followers": 188,
    "Net Worth": "$125",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 87,
    "Lepton": 0.271
  },
  {
    "Username": "0xc677…08cb",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$125",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 28,
    "Lepton": 0.271
  },
  {
    "Username": "0xee0d…e73a",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$127",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 22,
    "Lepton": 0.272
  },
  {
    "Username": "IvanStar",
    "Address": "0x2233de6edd2343651c1b6ca7e8161992714c3732",
    "Followers": 168,
    "Net Worth": "$126",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 84,
    "Lepton": 0.271
  },
  {
    "Username": "Archway",
    "Address": "0xe1fe39a7bfeaf42b78c35a27deb7a3861345b47c",
    "Followers": 246,
    "Net Worth": "$121",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 96,
    "Lepton": 0.268
  },
  {
    "Username": "0xee0c…a3aa",
    "Address": "",
    "Followers": 192,
    "Net Worth": "$118",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 88,
    "Lepton": 0.267
  },
  {
    "Username": "0x10ea…b443",
    "Address": "",
    "Followers": 70,
    "Net Worth": "$119",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 63,
    "Lepton": 0.267
  },
  {
    "Username": "Aditya13",
    "Address": "0x8519fcf253ea7208ff25414887f05bbad42691d9",
    "Followers": 659,
    "Net Worth": "$118",
    "Boson Weight": 9,
    "Lepton Weight": 3,
    "Boson": 133,
    "Lepton": 0.267
  },
  {
    "Username": "0xbc48…2332",
    "Address": "",
    "Followers": "1,673",
    "Net Worth": "$119",
    "Boson Weight": 12,
    "Lepton Weight": 3,
    "Boson": 181,
    "Lepton": 0.267
  },
  {
    "Username": "NASH",
    "Address": "0x32244799f46dad2de11781e9a18759d4a67fd6f4",
    "Followers": 127,
    "Net Worth": "$119",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 77,
    "Lepton": 0.267
  },
  {
    "Username": "0x756a…75f8",
    "Address": "",
    "Followers": 37,
    "Net Worth": "$119",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 51,
    "Lepton": 0.267
  },
  {
    "Username": "0x7f1f…b840",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$118",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.267
  },
  {
    "Username": "0x8475…392e",
    "Address": "",
    "Followers": 701,
    "Net Worth": "$118",
    "Boson Weight": 9,
    "Lepton Weight": 3,
    "Boson": 135,
    "Lepton": 0.267
  },
  {
    "Username": "0x3a9d…f3e7",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$114",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 22,
    "Lepton": 0.264
  },
  {
    "Username": "0x306d…5db7",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$112",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 19,
    "Lepton": 0.263
  },
  {
    "Username": "1BT1",
    "Address": "0x48b6e30b0a455973d98534a7efae6fa014fd6135",
    "Followers": 738,
    "Net Worth": "$114",
    "Boson Weight": 9,
    "Lepton Weight": 3,
    "Boson": 138,
    "Lepton": 0.264
  },
  {
    "Username": "0x7212…2a73",
    "Address": "",
    "Followers": 890,
    "Net Worth": "$110",
    "Boson Weight": 10,
    "Lepton Weight": 3,
    "Boson": 147,
    "Lepton": 0.262
  },
  {
    "Username": "0xc162…9bcd",
    "Address": "",
    "Followers": 413,
    "Net Worth": "$112",
    "Boson Weight": 7,
    "Lepton Weight": 3,
    "Boson": 114,
    "Lepton": 0.263
  },
  {
    "Username": "Amazon_eth",
    "Address": "0x2b5d8c0b9fb17e3c3b56fee12212c8e40ce4f1f2",
    "Followers": 862,
    "Net Worth": "$110",
    "Boson Weight": 10,
    "Lepton Weight": 3,
    "Boson": 145,
    "Lepton": 0.262
  },
  {
    "Username": "0x2342…85aa",
    "Address": "",
    "Followers": 70,
    "Net Worth": "$110",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 63,
    "Lepton": 0.262
  },
  {
    "Username": "0xca6f…4c1a",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$105",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.259
  },
  {
    "Username": "0xcced…1602",
    "Address": "",
    "Followers": 33,
    "Net Worth": "$106",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 49,
    "Lepton": 0.26
  },
  {
    "Username": "0x568e…2874",
    "Address": "",
    "Followers": 19,
    "Net Worth": "$104",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 41,
    "Lepton": 0.258
  },
  {
    "Username": "0x9a7f…32ee",
    "Address": "",
    "Followers": 41,
    "Net Worth": "$105",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 53,
    "Lepton": 0.259
  },
  {
    "Username": "0xfea2…8700",
    "Address": "",
    "Followers": 79,
    "Net Worth": "$105",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 65,
    "Lepton": 0.259
  },
  {
    "Username": "svetozar",
    "Address": "0xcc45aa3c62e6426c43e0d522032d79845918dd1b",
    "Followers": "1,172",
    "Net Worth": "$103",
    "Boson Weight": 11,
    "Lepton Weight": 3,
    "Boson": 161,
    "Lepton": 0.258
  },
  {
    "Username": "0xc73a…91b2",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$102",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 28,
    "Lepton": 0.257
  },
  {
    "Username": "0x04c4…4195",
    "Address": "",
    "Followers": 21,
    "Net Worth": "$102",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 42,
    "Lepton": 0.257
  },
  {
    "Username": "0x2f1b…2516",
    "Address": "",
    "Followers": 112,
    "Net Worth": "$98",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 73,
    "Lepton": 0.255
  },
  {
    "Username": "0x1acd…7617",
    "Address": "",
    "Followers": 8,
    "Net Worth": "$125",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 30,
    "Lepton": 0.271
  },
  {
    "Username": "0x3c33…b53d",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$97",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 26,
    "Lepton": 0.254
  },
  {
    "Username": "nofear",
    "Address": "0x5198700712a79054f2d5ec911760079ea55a6139",
    "Followers": 22,
    "Net Worth": "$97",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 43,
    "Lepton": 0.254
  },
  {
    "Username": "SUPI",
    "Address": "0x3180c2226574bc1c8aa2947be2c51e8d283db153",
    "Followers": 150,
    "Net Worth": "$99",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 81,
    "Lepton": 0.255
  },
  {
    "Username": "0x952f…5f0b",
    "Address": "",
    "Followers": 21,
    "Net Worth": "$94",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 42,
    "Lepton": 0.252
  },
  {
    "Username": "MashaStaR",
    "Address": "0xe5effd4fde4ce00dd1b11c35ac4bf4e33b83fcd5",
    "Followers": 118,
    "Net Worth": "$97",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 75,
    "Lepton": 0.254
  },
  {
    "Username": "0x43c6…2ed8",
    "Address": "",
    "Followers": 327,
    "Net Worth": "$91",
    "Boson Weight": 7,
    "Lepton Weight": 3,
    "Boson": 105,
    "Lepton": 0.25
  },
  {
    "Username": "0x04ba…4570",
    "Address": "",
    "Followers": 43,
    "Net Worth": "$95",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 53,
    "Lepton": 0.253
  },
  {
    "Username": "0x6c5d…944c",
    "Address": "",
    "Followers": 4,
    "Net Worth": "$96",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 24,
    "Lepton": 0.253
  },
  {
    "Username": "0xca13…f0a6",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$92",
    "Boson Weight": 0,
    "Lepton Weight": 3,
    "Boson": 0,
    "Lepton": 0.251
  },
  {
    "Username": "0x54e2…56da",
    "Address": "",
    "Followers": 8,
    "Net Worth": "$90",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 30,
    "Lepton": 0.249
  },
  {
    "Username": "0x29be…64e0",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$88",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 38,
    "Lepton": 0.248
  },
  {
    "Username": "0x959c…d815",
    "Address": "",
    "Followers": 37,
    "Net Worth": "$91",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 51,
    "Lepton": 0.25
  },
  {
    "Username": "0x1566…2cc7",
    "Address": "",
    "Followers": 4,
    "Net Worth": "$84",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 24,
    "Lepton": 0.245
  },
  {
    "Username": "LeeSM",
    "Address": "0x17e9283e1b209f2bef7c8b611cd7825acd82e06f",
    "Followers": 161,
    "Net Worth": "$82",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 83,
    "Lepton": 0.244
  },
  {
    "Username": "0xa4c0…fac3",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$83",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 28,
    "Lepton": 0.244
  },
  {
    "Username": "0x648e…9149",
    "Address": "",
    "Followers": 47,
    "Net Worth": "$82",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 55,
    "Lepton": 0.244
  },
  {
    "Username": "VikaV",
    "Address": "0xaec9464b45e1992a6bcb57400e45eceb00492631",
    "Followers": 127,
    "Net Worth": "$84",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 77,
    "Lepton": 0.245
  },
  {
    "Username": "0x221b…9f56",
    "Address": "",
    "Followers": 415,
    "Net Worth": "$82",
    "Boson Weight": 7,
    "Lepton Weight": 3,
    "Boson": 114,
    "Lepton": 0.244
  },
  {
    "Username": "0x2ab8…40fa",
    "Address": "",
    "Followers": 14,
    "Net Worth": "$78",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 37,
    "Lepton": 0.241
  },
  {
    "Username": "0xbf42…8806",
    "Address": "",
    "Followers": 162,
    "Net Worth": "$76",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 83,
    "Lepton": 0.239
  },
  {
    "Username": "0xb9c6…d0b5",
    "Address": "",
    "Followers": 42,
    "Net Worth": "$80",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 53,
    "Lepton": 0.242
  },
  {
    "Username": "0xf886…94b3",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$78",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 38,
    "Lepton": 0.241
  },
  {
    "Username": "0x41da…2b23",
    "Address": "",
    "Followers": 262,
    "Net Worth": "$68",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 98,
    "Lepton": 0.232
  },
  {
    "Username": "iwk322",
    "Address": "0x6f7a7f13e13376ca076ac514d1bec3871c307b7d",
    "Followers": 185,
    "Net Worth": "$76",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 87,
    "Lepton": 0.239
  },
  {
    "Username": "0xcd31…42c8",
    "Address": "",
    "Followers": "1,672",
    "Net Worth": "$75",
    "Boson Weight": 12,
    "Lepton Weight": 3,
    "Boson": 181,
    "Lepton": 0.238
  },
  {
    "Username": "0x4cd1…17da",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$77",
    "Boson Weight": 0,
    "Lepton Weight": 3,
    "Boson": 0,
    "Lepton": 0.24
  },
  {
    "Username": "virtualsnake",
    "Address": "0x0c8298b2d2166032fa58f27bfc73dc2a9c4d4f99",
    "Followers": 65,
    "Net Worth": "$72",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 61,
    "Lepton": 0.236
  },
  {
    "Username": "0x7ae7…5079",
    "Address": "",
    "Followers": 520,
    "Net Worth": "$72",
    "Boson Weight": 8,
    "Lepton Weight": 3,
    "Boson": 123,
    "Lepton": 0.236
  },
  {
    "Username": "0x6d95…6e2d",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$73",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.237
  },
  {
    "Username": "0xf2f8…b054",
    "Address": "",
    "Followers": 74,
    "Net Worth": "$72",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 64,
    "Lepton": 0.236
  },
  {
    "Username": "Nanachi",
    "Address": "0xf71555b68ae1025c8ba96e1bc88ea77f09f11c13",
    "Followers": 7,
    "Net Worth": "$73",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 29,
    "Lepton": 0.237
  },
  {
    "Username": "0x2e71…6a1e",
    "Address": "",
    "Followers": 127,
    "Net Worth": "$69",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 77,
    "Lepton": 0.233
  },
  {
    "Username": "0x8212…b959",
    "Address": "",
    "Followers": 34,
    "Net Worth": "$71",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 49,
    "Lepton": 0.235
  },
  {
    "Username": "0x036e…9f71",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$66",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 38,
    "Lepton": 0.231
  },
  {
    "Username": "0x0a61…6573",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$69",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.233
  },
  {
    "Username": "0xdf1f…6d54",
    "Address": "",
    "Followers": 10,
    "Net Worth": "$67",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 33,
    "Lepton": 0.232
  },
  {
    "Username": "NOSTRESS",
    "Address": "0x3674c0571de1c308580d800897dc6b8093bec991",
    "Followers": 97,
    "Net Worth": "$67",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 70,
    "Lepton": 0.232
  },
  {
    "Username": "0x57b8…78e5",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$65",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.23
  },
  {
    "Username": "kashevar",
    "Address": "0xc3bd4ace04dae55c7859912d1bd1aeef51bb6142",
    "Followers": 146,
    "Net Worth": "$67",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 80,
    "Lepton": 0.232
  },
  {
    "Username": "pompomejung",
    "Address": "0x6bcfe7307663bb2b0c1c70294c8af1ea01fef0de",
    "Followers": 148,
    "Net Worth": "$66",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 81,
    "Lepton": 0.231
  },
  {
    "Username": "0x477b…2781",
    "Address": "",
    "Followers": 220,
    "Net Worth": "$65",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 92,
    "Lepton": 0.23
  },
  {
    "Username": "0x7570…360e",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$65",
    "Boson Weight": 0,
    "Lepton Weight": 3,
    "Boson": 0,
    "Lepton": 0.23
  },
  {
    "Username": "0x9683…7f64",
    "Address": "",
    "Followers": 38,
    "Net Worth": "$63",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 51,
    "Lepton": 0.228
  },
  {
    "Username": "Midas",
    "Address": "0xafb0f45c9f7924b2928f7f3f82194df204242bba",
    "Followers": 70,
    "Net Worth": "$63",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 63,
    "Lepton": 0.228
  },
  {
    "Username": "0xf16a…99c0",
    "Address": "",
    "Followers": 84,
    "Net Worth": "$60",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 67,
    "Lepton": 0.225
  },
  {
    "Username": "0xc38f…6009",
    "Address": "",
    "Followers": 663,
    "Net Worth": "$63",
    "Boson Weight": 9,
    "Lepton Weight": 3,
    "Boson": 133,
    "Lepton": 0.228
  },
  {
    "Username": "0x108f…4dbf",
    "Address": "",
    "Followers": 244,
    "Net Worth": "$63",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 95,
    "Lepton": 0.228
  },
  {
    "Username": "0xf6bb…fd79",
    "Address": "",
    "Followers": 42,
    "Net Worth": "$63",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 53,
    "Lepton": 0.228
  },
  {
    "Username": "0x063e…cdaf",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$64",
    "Boson Weight": 0,
    "Lepton Weight": 3,
    "Boson": 0,
    "Lepton": 0.229
  },
  {
    "Username": "0x3005…6c32",
    "Address": "",
    "Followers": 35,
    "Net Worth": "$62",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 50,
    "Lepton": 0.227
  },
  {
    "Username": "0x26e1…62e7",
    "Address": "",
    "Followers": 8,
    "Net Worth": "$59",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 30,
    "Lepton": 0.224
  },
  {
    "Username": "0xef70…b051",
    "Address": "",
    "Followers": 35,
    "Net Worth": "$60",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 50,
    "Lepton": 0.225
  },
  {
    "Username": "0xc029…596e",
    "Address": "",
    "Followers": 7,
    "Net Worth": "$56",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 29,
    "Lepton": 0.221
  },
  {
    "Username": "0x6352…20f7",
    "Address": "",
    "Followers": 31,
    "Net Worth": "$58",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 48,
    "Lepton": 0.223
  },
  {
    "Username": "0xe399…bfdb",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$61",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 26,
    "Lepton": 0.226
  },
  {
    "Username": "0x825b…7ff8",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$55",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 38,
    "Lepton": 0.22
  },
  {
    "Username": "nongsajom",
    "Address": "0xa5b0a40718a5e32ead804683932a67234af1c1ea",
    "Followers": 264,
    "Net Worth": "$55",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 98,
    "Lepton": 0.22
  },
  {
    "Username": "0xdb67…d0e2",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$54",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.219
  },
  {
    "Username": "0xbf5d…1935",
    "Address": "",
    "Followers": 226,
    "Net Worth": "$52",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 93,
    "Lepton": 0.217
  },
  {
    "Username": "0xad4b…fc90",
    "Address": "",
    "Followers": 237,
    "Net Worth": "$54",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 94,
    "Lepton": 0.219
  },
  {
    "Username": "0x4d1a…6832",
    "Address": "",
    "Followers": 9,
    "Net Worth": "$50",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 32,
    "Lepton": 0.215
  },
  {
    "Username": "ad4el",
    "Address": "0xe31fcd91d04abd73328f210b9eff1fa481f743f5",
    "Followers": 75,
    "Net Worth": "$49",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 64,
    "Lepton": 0.214
  },
  {
    "Username": "0x2e15…bf29",
    "Address": "",
    "Followers": 105,
    "Net Worth": "$48",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 72,
    "Lepton": 0.213
  },
  {
    "Username": "picarsso",
    "Address": "0xe2664d09796f9ac9e919c3ab01c74a01d5837703",
    "Followers": 150,
    "Net Worth": "$49",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 81,
    "Lepton": 0.214
  },
  {
    "Username": "0x3a99…985e",
    "Address": "",
    "Followers": 42,
    "Net Worth": "$48",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 53,
    "Lepton": 0.213
  },
  {
    "Username": "BabyShaq21",
    "Address": "0xbc3d939dab46e05406af32cb8068a2675f68a151",
    "Followers": 30,
    "Net Worth": "$47",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 47,
    "Lepton": 0.212
  },
  {
    "Username": "0x74dd…b76a",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$47",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 15,
    "Lepton": 0.212
  },
  {
    "Username": "0x01c4…433c",
    "Address": "",
    "Followers": 47,
    "Net Worth": "$46",
    "Boson Weight": 4,
    "Lepton Weight": 3,
    "Boson": 55,
    "Lepton": 0.211
  },
  {
    "Username": "0x9d08…9c5c",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$45",
    "Boson Weight": 1,
    "Lepton Weight": 3,
    "Boson": 19,
    "Lepton": 0.21
  },
  {
    "Username": "0xa580…09b0",
    "Address": "",
    "Followers": 4,
    "Net Worth": "$44",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 24,
    "Lepton": 0.208
  },
  {
    "Username": "0x28af…86fb",
    "Address": "",
    "Followers": "1,244",
    "Net Worth": "$46",
    "Boson Weight": 11,
    "Lepton Weight": 3,
    "Boson": 164,
    "Lepton": 0.211
  },
  {
    "Username": "CoinbaseNFT",
    "Address": "0xd1eba2a0888c3965f26e481b9e990627087626e1",
    "Followers": 8,
    "Net Worth": "$44",
    "Boson Weight": 2,
    "Lepton Weight": 3,
    "Boson": 30,
    "Lepton": 0.208
  },
  {
    "Username": "0xdb18…82b4",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$42",
    "Boson Weight": 0,
    "Lepton Weight": 3,
    "Boson": 0,
    "Lepton": 0.206
  },
  {
    "Username": "0xb431…d24e",
    "Address": "",
    "Followers": 836,
    "Net Worth": "$44",
    "Boson Weight": 9,
    "Lepton Weight": 3,
    "Boson": 144,
    "Lepton": 0.208
  },
  {
    "Username": "0x8db8…ac62",
    "Address": "",
    "Followers": 119,
    "Net Worth": "$45",
    "Boson Weight": 5,
    "Lepton Weight": 3,
    "Boson": 75,
    "Lepton": 0.21
  },
  {
    "Username": "0xb394…133e",
    "Address": "",
    "Followers": 182,
    "Net Worth": "$41",
    "Boson Weight": 6,
    "Lepton Weight": 3,
    "Boson": 86,
    "Lepton": 0.205
  },
  {
    "Username": "0xa5bf…1a6a",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$42",
    "Boson Weight": 0,
    "Lepton Weight": 3,
    "Boson": 0,
    "Lepton": 0.206
  },
  {
    "Username": "0x044f…3fbe",
    "Address": "",
    "Followers": 174,
    "Net Worth": "$39",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 85,
    "Lepton": 0.202
  },
  {
    "Username": "0xee5e…5c0f",
    "Address": "",
    "Followers": 29,
    "Net Worth": "$41",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 47,
    "Lepton": 0.205
  },
  {
    "Username": "0x9afa…f390",
    "Address": "",
    "Followers": 27,
    "Net Worth": "$40",
    "Boson Weight": 3,
    "Lepton Weight": 3,
    "Boson": 46,
    "Lepton": 0.204
  },
  {
    "Username": "0xc88b…379c",
    "Address": "",
    "Followers": "2,375",
    "Net Worth": "$38",
    "Boson Weight": 13,
    "Lepton Weight": 2,
    "Boson": 203,
    "Lepton": 0.201
  },
  {
    "Username": "0xef99…dcd2",
    "Address": "",
    "Followers": 173,
    "Net Worth": "$39",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 85,
    "Lepton": 0.202
  },
  {
    "Username": "0xf8cc…b863",
    "Address": "",
    "Followers": 206,
    "Net Worth": "$35",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 90,
    "Lepton": 0.197
  },
  {
    "Username": "0x4e93…9a7f",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$37",
    "Boson Weight": 1,
    "Lepton Weight": 2,
    "Boson": 15,
    "Lepton": 0.2
  },
  {
    "Username": "0xa1b0…0181",
    "Address": "",
    "Followers": 224,
    "Net Worth": "$37",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 93,
    "Lepton": 0.2
  },
  {
    "Username": "0x274f…f37d",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$39",
    "Boson Weight": 1,
    "Lepton Weight": 2,
    "Boson": 15,
    "Lepton": 0.202
  },
  {
    "Username": "0x998d…e636",
    "Address": "",
    "Followers": "2,299",
    "Net Worth": "$36",
    "Boson Weight": 13,
    "Lepton Weight": 2,
    "Boson": 201,
    "Lepton": 0.198
  },
  {
    "Username": "0x64f6…c5d0",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$33",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 38,
    "Lepton": 0.194
  },
  {
    "Username": "0xc09a…16e0",
    "Address": "",
    "Followers": 42,
    "Net Worth": "$35",
    "Boson Weight": 3,
    "Lepton Weight": 2,
    "Boson": 53,
    "Lepton": 0.197
  },
  {
    "Username": "0x368d…6d0f",
    "Address": "",
    "Followers": 96,
    "Net Worth": "$34",
    "Boson Weight": 5,
    "Lepton Weight": 2,
    "Boson": 70,
    "Lepton": 0.195
  },
  {
    "Username": "0x9fdd…2592",
    "Address": "",
    "Followers": 259,
    "Net Worth": "$31",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 97,
    "Lepton": 0.191
  },
  {
    "Username": "I_AM_SEKIBA",
    "Address": "0x9f41f7213f24e4e4b58d5d7deb390237230fc760",
    "Followers": "3,297",
    "Net Worth": "$30",
    "Boson Weight": 15,
    "Lepton Weight": 2,
    "Boson": 227,
    "Lepton": 0.189
  },
  {
    "Username": "0x30be…2988",
    "Address": "",
    "Followers": 156,
    "Net Worth": "$30",
    "Boson Weight": 5,
    "Lepton Weight": 2,
    "Boson": 82,
    "Lepton": 0.189
  },
  {
    "Username": "btcstx",
    "Address": "0x378f27cd65a38ad609e2220896ca17dc00890c67",
    "Followers": 95,
    "Net Worth": "$29",
    "Boson Weight": 5,
    "Lepton Weight": 2,
    "Boson": 70,
    "Lepton": 0.188
  },
  {
    "Username": "0xc47b…8a81",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$25",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 26,
    "Lepton": 0.181
  },
  {
    "Username": "0x0219…d46b",
    "Address": "",
    "Followers": 0,
    "Net Worth": "$28",
    "Boson Weight": 0,
    "Lepton Weight": 2,
    "Boson": 0,
    "Lepton": 0.186
  },
  {
    "Username": "0xdb46…11eb",
    "Address": "",
    "Followers": 111,
    "Net Worth": "$27",
    "Boson Weight": 5,
    "Lepton Weight": 2,
    "Boson": 73,
    "Lepton": 0.185
  },
  {
    "Username": "0x0cd2…1843",
    "Address": "",
    "Followers": 9,
    "Net Worth": "$27",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 32,
    "Lepton": 0.185
  },
  {
    "Username": "0x73cc…e60a",
    "Address": "",
    "Followers": 42,
    "Net Worth": "$25",
    "Boson Weight": 3,
    "Lepton Weight": 2,
    "Boson": 53,
    "Lepton": 0.181
  },
  {
    "Username": "0x8160…3c3c",
    "Address": "",
    "Followers": 6,
    "Net Worth": "$28",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 28,
    "Lepton": 0.186
  },
  {
    "Username": "0xdb10…1d1b",
    "Address": "",
    "Followers": "1,188",
    "Net Worth": "$28",
    "Boson Weight": 11,
    "Lepton Weight": 2,
    "Boson": 161,
    "Lepton": 0.186
  },
  {
    "Username": "0xdaba…f61b",
    "Address": "",
    "Followers": 4,
    "Net Worth": "$27",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 24,
    "Lepton": 0.185
  },
  {
    "Username": "0xa148…87a5",
    "Address": "",
    "Followers": 392,
    "Net Worth": "$23",
    "Boson Weight": 7,
    "Lepton Weight": 2,
    "Boson": 112,
    "Lepton": 0.177
  },
  {
    "Username": "0x9a1f…821c",
    "Address": "",
    "Followers": 174,
    "Net Worth": "$26",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 85,
    "Lepton": 0.183
  },
  {
    "Username": "relixop",
    "Address": "0x08586520fb31723fc2559c3671cadb89189af739",
    "Followers": 280,
    "Net Worth": "$25",
    "Boson Weight": 7,
    "Lepton Weight": 2,
    "Boson": 100,
    "Lepton": 0.181
  },
  {
    "Username": "0x9505…bcf5",
    "Address": "",
    "Followers": 8,
    "Net Worth": "$25",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 30,
    "Lepton": 0.181
  },
  {
    "Username": "0x3962…4772",
    "Address": "",
    "Followers": 15,
    "Net Worth": "$24",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 38,
    "Lepton": 0.179
  },
  {
    "Username": "0x3eac…f5c0",
    "Address": "",
    "Followers": 36,
    "Net Worth": "$25",
    "Boson Weight": 3,
    "Lepton Weight": 2,
    "Boson": 50,
    "Lepton": 0.181
  },
  {
    "Username": "0x3647…570b",
    "Address": "",
    "Followers": 256,
    "Net Worth": "$23",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 97,
    "Lepton": 0.177
  },
  {
    "Username": "0xbf49…84a4",
    "Address": "",
    "Followers": 176,
    "Net Worth": "$24",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 85,
    "Lepton": 0.179
  },
  {
    "Username": "0x805e…44a8",
    "Address": "",
    "Followers": 5,
    "Net Worth": "$21",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 26,
    "Lepton": 0.173
  },
  {
    "Username": "0x96e6…4610",
    "Address": "",
    "Followers": 17,
    "Net Worth": "$22",
    "Boson Weight": 3,
    "Lepton Weight": 2,
    "Boson": 39,
    "Lepton": 0.175
  },
  {
    "Username": "creator",
    "Address": "0x79450cf6152e75c6fcfc88e95c8b8ee10f7e7e43",
    "Followers": 235,
    "Net Worth": "$20",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 94,
    "Lepton": 0.171
  },
  {
    "Username": "0xbc8e…6b3e",
    "Address": "",
    "Followers": 33,
    "Net Worth": "$20",
    "Boson Weight": 3,
    "Lepton Weight": 2,
    "Boson": 49,
    "Lepton": 0.171
  },
  {
    "Username": "0x2f61…6e1b",
    "Address": "",
    "Followers": 2,
    "Net Worth": "$21",
    "Boson Weight": 1,
    "Lepton Weight": 2,
    "Boson": 19,
    "Lepton": 0.173
  },
  {
    "Username": "0x6f34…f0bb",
    "Address": "",
    "Followers": 16,
    "Net Worth": "$18",
    "Boson Weight": 3,
    "Lepton Weight": 2,
    "Boson": 38,
    "Lepton": 0.167
  },
  {
    "Username": "0xbcc3…a0ba",
    "Address": "",
    "Followers": "2,125",
    "Net Worth": "$17",
    "Boson Weight": 13,
    "Lepton Weight": 2,
    "Boson": 196,
    "Lepton": 0.164
  },
  {
    "Username": "0x6e41…a8fd",
    "Address": "",
    "Followers": "1,121",
    "Net Worth": "$17",
    "Boson Weight": 10,
    "Lepton Weight": 2,
    "Boson": 158,
    "Lepton": 0.164
  },
  {
    "Username": "0xf15e…af2f",
    "Address": "",
    "Followers": "1,817",
    "Net Worth": "$19",
    "Boson Weight": 12,
    "Lepton Weight": 2,
    "Boson": 186,
    "Lepton": 0.169
  },
  {
    "Username": "0x3483…d6ab",
    "Address": "",
    "Followers": 126,
    "Net Worth": "$16",
    "Boson Weight": 5,
    "Lepton Weight": 2,
    "Boson": 76,
    "Lepton": 0.162
  },
  {
    "Username": "0x14d4…e2ae",
    "Address": "",
    "Followers": 3,
    "Net Worth": "$18",
    "Boson Weight": 1,
    "Lepton Weight": 2,
    "Boson": 22,
    "Lepton": 0.167
  },
  {
    "Username": "0xb15d…67cd",
    "Address": "",
    "Followers": 211,
    "Net Worth": "$19",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 91,
    "Lepton": 0.169
  },
  {
    "Username": "0x627c…af25",
    "Address": "",
    "Followers": 4,
    "Net Worth": "$18",
    "Boson Weight": 2,
    "Lepton Weight": 2,
    "Boson": 24,
    "Lepton": 0.167
  },
  {
    "Username": "0x558c…028d",
    "Address": "",
    "Followers": 919,
    "Net Worth": "$16",
    "Boson Weight": 10,
    "Lepton Weight": 2,
    "Boson": 148,
    "Lepton": 0.162
  },
  {
    "Username": "0xa496…c443",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$16",
    "Boson Weight": 1,
    "Lepton Weight": 2,
    "Boson": 15,
    "Lepton": 0.162
  },
  {
    "Username": "0xccc9…3422",
    "Address": "",
    "Followers": 44,
    "Net Worth": "$16",
    "Boson Weight": 4,
    "Lepton Weight": 2,
    "Boson": 54,
    "Lepton": 0.162
  },
  {
    "Username": "0x6abc…da18",
    "Address": "",
    "Followers": 195,
    "Net Worth": "$13",
    "Boson Weight": 6,
    "Lepton Weight": 2,
    "Boson": 88,
    "Lepton": 0.154
  },
  {
    "Username": "0xcdc2…7438",
    "Address": "",
    "Followers": 1,
    "Net Worth": "$17",
    "Boson Weight": 1,
    "Lepton Weight": 2,
    "Boson": 15,
    "Lepton": 0.164
  },
  {
    "Username": "0x42dd…c8b8",
    "Address": "",
    "Followers": 490,
    "Net Worth": "$18",
    "Boson Weight": 8,
    "Lepton Weight": 2,
    "Boson": 120,
    "Lepton": 0.167
  }
];