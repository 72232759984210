import Web3 from 'web3'

export function Joo3 () {
  return new Web3(Web3.givenProvider || 'http://localhost:8545')
}

export class Joo {
  constructor (_web3=null) {
    this.abi = require('./GenericABI.json')
    if (_web3) {
      this.X = _web3
      
    } else {
      this.X = new Web3(Web3.givenProvider || 'http://localhost:8545')
    }
    this.tract = (tokenAddress) => new this.X.eth.Contract(this.abi, tokenAddress)
  }

  callOrRequest(_walletAddress, transaction, callback, requestMode=false) {
    // make a contract call or request with the given callback
    if (requestMode) {
      return transaction.call.request({from: _walletAddress}, callback)
    } else {
      return transaction.call({from: _walletAddress}).then(callback)
    }
  }

  createGenContract (tokenAddress) {
    return new this.X.eth.Contract(this.abi, tokenAddress);
  }

  balanceOf ( tokenAddress, walletAddress) {
    
    return this.tract(tokenAddress).methods.balanceOf(walletAddress)
  }

  toDisplay ( str, dec ) {
    let left, right
    console.log("joo.toDisplay: ", str, dec)
    let result
    if (str == "" || str == null) {

    } else {
      if (dec == 18) {
       result = this.X.utils.fromWei(str.toString(), 'ether')
       if (str.length < 18) {
        result = Number(result).toFixed(2-Math.floor(Math.log(Number(result))/Math.log(10)))
       } else {
        result = Number(result).toFixed(2)
       }
      } else {
        if ( dec == 6 ) {
          result = (Number(str)/1e6).toFixed(6)
        } else {
          if (str.length > dec) {
            right = str.substring(str.length - dec, str.length)
            left = str.substring(0, str.length - dec)
            result = left + "." + right
            result = Number(result).toFixed(2)
          } else {
            left = "0"
            right = str.padStart(dec, '0')
            result = left + "." + right
            console.log("joo.toDisplay less than 1: ",result)
            result = Number(result).toFixed(2-Math.floor(Math.log(Number(result))/Math.log(10)))
          }
          
        }
      }
    }
    
    return result
  } 

  fromDisplay ( str, dec ) {
    let left, right
    let result

    if (str == "" || str == null) {

    } else {
      if (dec == 18) {
        result = this.X.utils.toWei(str.toString(), 'ether')
      } else {
        let ix = str.indexOf(".")
        if (ix > -1) {
          left = str.substring(0,ix)
          right = str.substring(ix+1,str.length)
          right = right.padEnd(dec,"0")
          console.log(right)
          if (Number(left) != 0 && left.length>0) {
            result = left + right
          } else {
            right = right.replace(/^0+/,"")
            result = right
          }
        } else {
          result = str.concat("0".repeat(dec))
        }
      }
    }
    console.log("fromDisplay: ", str, dec, result)
    return result
  }

  balanceFromConfig ( configs, configname, walletAddress) {
    return this.balanceOf(
                configs[configname]["address"],
                walletAddress)
  }

  getNativeBalance = ( walletAddress, callback ) => {
      return this.X.eth.getBalance(walletAddress, callback)
          
      }

  balanceRequest ( tokenAddress, walletAddress, callback ) {
    return this.callOrRequest( 
      walletAddress, 
      this.balanceOf(tokenAddress, walletAddress),
      callback,
      true)
  }

  balanceOfDeadRequest ( tokenAddress, walletAddress, callback ) {
    return this.callOrRequest( 
      walletAddress, 
      this.balanceOf(tokenAddress, "0x000000000000000000000000000000000000dead"),
      callback,
      true)
  }

  totalSupplyRequest ( tokenAddress, walletAddress, callback ) {
    return this.callOrRequest(
      walletAddress,
      this.tract(tokenAddress).methods.totalSupply(),
      callback,
      true
      )
  }

  approve (tokenAddress, spender, walletAddress, amount, callback = () => {}) {

    return this.tract(tokenAddress).methods.approve(spender, amount).send({from: walletAddress}).then(callback)
  }

  allowance (tokenAddress, spender, walletAddress, callback = () => {}) {

    return this.tract(tokenAddress).methods.allowance(walletAddress, spender).call({from: walletAddress}).then(callback)
  }

  estimateGas (transaction, walletAddress, value = "0", callback = ()=>{}, errf = ()=>{}) {
    transaction.estimateGas(
      {from: walletAddress, value: value},
      function (error, estimatedGas) {
        if (error != null) {
          callback(estimatedGas)
        } else {
          errf(error)
        }
      }
      )

  }
}

