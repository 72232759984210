export function chainMap (id) {
	  if (id === "0x89") return "polygon"
	  if (id === "0xfa") return "fantom"
	  if (id === "0xfa2") return "fantom-test"
	  if (id === "0x38") return "bsc"
	  if (id === "0x1") return "eth"
	  if (id === "0x64") return "gnosis"
	  if (id === "0x4e454152") return "aurora"
	  if (id === "0x250") return "astar"
	  if (id === "0xa") return "optimism"
	  if (id === "0x1e") return "rsk"
	  if (id === "0x7f08") return "bitgert"

	  if (id === "0xa4ba") return "arbitrum-nova"
	  if (id === "0x80") return "huobi"
	  if (id === "0x42") return "okex"
	  if (id === "0x141") return "kcc"
	  if (id === "0x505") return "moonriver"
	  if (id === "0xa4b1") return "arbitrum"
	  if (id === "0xa4ec") return "celo"

	  if (id === "0x504") return "moonbeam"
	  if (id === "0x7a") return "fuse"
	  if (id === "0x14") return "elastos"
	  if (id === "0xa516") return "oasis"
	  if (id === "0x6a") return "velas"
	  if (id === "0x2710") return "smart-bch"
	  if (id === "0x7d1") return "milkomeda-c1"

	  if (id === "0x2019") return "klaytn"
	  if (id === "0x63564c40") return "harmony"
	  if (id === "0x868") return "findora"
	  if (id === "0x378") return "wanchain"
	  if (id === "0x2329") return "evmos"
	  if (id === "0x6c") return "thundercore"
	  if (id === "0x52") return "meter"

	  if (id === "0x7d0") return "dogechain"
	  if (id === "0x18") return "kardia"
	  if (id === "0x400") return "clover"
	  if (id === "0x1e14") return "canto"
	  if (id === "0x2711") return "ethw"
	  if (id === "0x4d2") return "step-network"
	  if (id === "0x150") return "shiden"

	  if (id === "0x7d2") return "milkomeda-a1"
	  if (id === "0x4fb2") return "muuchain"
	  if (id === "0x406") return "conflux"
	  if (id === "0x334") return "callisto"
	  return null
}

export function enforceChain(chainName, callback = () => {}) {
	(chainMap(window.ethereum.chainId) === chainName) ? callback() : alert("Please switch to " + chainName)
}