import './App.scss';
import { Dapp } from './Dapp.js';

function App() {
  return (
    <div className="App">
      <Dapp chain="optimism" onChainConnect={()=>{}} />
      
    </div>
  );
}

export default App;
