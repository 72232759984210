

export const isLP = (token) => {
	if (token.hasOwnProperty("tokens")) {
		return true
	} else {
		return false
	}
}

export const core = {
	"moonbeam": {
		"core": {
			"currency": "glmr",
			"wrapped": "wglmr",
			"icon": "wglmr"
		},
		"links": {
			"icons": {
				"pre": "https://raw.githubusercontent.com/stellaswap/assets/main/tokenlist/",
				"post": "/logo.png"
			}
		},
		"tokens": {
			"wglmr": {
				"address": "0xAcc15dC74880C9944775448304B263D191c6077F"
			},
			"wstdot": {
				"address": "0x191cf2602Ca2e534c5Ccae7BCBF4C46a704bb949",
				"dec": 10,
				"altabi": require("../DappABIs/wstdot.json")
			},
			"xcdot": {
				"address": "0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080",
				"dec": 10
			},
			"whusdc": {
				"address": "0x931715FEE2d06333043d11F658C8CE934aC61D0c",
				"dec": 6
			},
			"stella": {
				"address": "0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2"

			},
			"well": {
				"address": "0x511aB53F793683763E5a8829738301368a2411E3"
			},
			"musdc": {
				"address": "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
				"dec": 6
			},
			"wstdot-xcdot": {
				"address": "0x61BF1b38930e37850D459f3CB926Cd197F5F88c0",
				"tokens": ["wstdot","xcdot"],	
				"dex": "stellaswap"
			},
			"whusdc-wglmr": {
				"address": "0x8CCBbcAF58f5422F6efD4034d8E8a3c9120ADf79",
				"tokens": ["whusdc","wglmr"],	
				"dex": "stellaswap"
			}
			//0x61bf1b38930e37850d459f3cb926cd197f5f88c0 wstdot-xcdot
			//0xa927e1e1e044ca1d9fe1854585003477331fe2af wglmr-xcdot
			//0x0e358838ce72d5e61e0018a2ffac4bec5f4c88d2

		},
		"routers": {
			"stellaswap": {
				"address": "0x70085a09D30D6f8C4ecF6eE10120d1847383BB57",
				"abi": require("./UniV2.json"),
				"lpabi": require("./UniV2LP.json"),
				"pairs": [
					["wglmr","xcdot"],
					["whusdc","wglmr"],
					["wstdot","xcdot"],
					["stella","wglmr"],
					["stella","musdc"],
					["well","wglmr"],
					["well","xcdot"],
					["stella","well"]
				]
			}
		}
	},
	"optimism": {
		"core": {
			"currency": "op",
			"wrapped": "op",
			"icon": "op"
		},
		"links": {
			"icons": {
				"pre": "https://assets.smold.app/api/token/10/",
				"post": "/logo.svg"
			}
		},
		"tokens": {
			"op": {
				"address": "0x4200000000000000000000000000000000000042"
			}
		}
	}
}

export const getDecimals = (token) => {
	let t
	console.log(typeof token)
	if (token == ""){
		return 0
	} else {
		if (typeof token == "string" || typeof token == "undefined") {
			t = core.moonbeam.tokens[token]
		} else {
			t = token
		}
		if (t.hasOwnProperty("dec")) {
			return t.dec
		} else {
			return 18
		}
	}
	
}